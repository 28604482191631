import { useMutation, useQuery } from '@tanstack/react-query';
import draftToHtml from 'draftjs-to-html';
import { GitMerge, X } from 'lucide-react';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate } from 'react-router-dom';
import { TaskListTypeConst } from '../BoardView/BoardView.constant';
import { useUser } from '../Hook/useUser';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import '../Template/template.css';
import {
  convertHtmlToRawContent,
  convertTimeIn12ToMillisecond,
  floatToDayHour,
  getCurrentTimeInMilliseconds,
  getHoursFromMs,
  getMinutesFromMs,
  getPeriodFromMs,
  GlobalContext,
  taskState,
  timeBased24StringTo12Milliseconds,
  validateTaskWithTemplateTest,
} from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import { Button, ToggleButton } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import ToolTip from '../_components/Tooltip/ToolTip';
import { MENU, RT, USER_LIST_ACTIONS } from '../_constants';
import { TEMPLATE_ERROR_MSGS, VALIDATION_VALUES } from '../_constants/template.constants';
import useDebounce from '../_helpers/useDebounce';
import { clientService, taskService, userService } from '../_services';
import { labelService } from '../_services/label.service';
import { templateService } from '../_services/template.service';
import { TaskAddFormSection } from './TaskComponents';
import TaskAddRelatedTaskList from './TaskComponents/TaskAddRelatedTaskList';
import { InlineRelatedTaskAdd } from './TaskTableContent/InlineRelatedTaskAdd';
import { ModifiedInlineTaskAdd } from './TaskTableContent/ModifiedInlineTaskAdd';
import SingleRelatedTaskPreview from './TaskTableContent/SingleRelatedTaskPreview';
import './task.css';
import {
  customTemplateSelectBoxStyles,
  formMode,
  labelSelectValues,
  TASK_TABS,
  taskAssignUserSelect,
  taskErrorKeyValues,
  taskValidationConstant,
  templateSelectValues,
} from './taskConstants';
import {
  getHours,
  getLabelIsActive,
  getSubTaskIsActive,
  getTaskDataFromTemplate,
  validateHours,
} from './taskValidation';

export function TaskAdd({
  tableRef,
  // id = null,
  okr_Parent_id,
  handleClose = () => {},
  defaultTemplate = null,
  templateSelectionDisabled = false,
  defaultTemplateId = null, //Default template id has higher priority, so if defaultTemplateId is provided then template will be selected based on id, otherwise template name.
  selectDefaultTemplateIfPresent = false,
  // defaultTemplateName = 'Default Template', //For identifying the default added template. This name is reserved for default template.
  projectID = null,
  milestoneID = null,
  queryKey = 'default-template',
  componentMode = formMode.FORM, // the component mode is default "form", it may be change for add task component
  showHeaderAndFooter = false, // to show header and footer
  assignTo = null,
  defaultAssignee = null,
  afterTaskAddFn = null,
  from = 'list',
  customDates = {},
  startTimeData = '',
  clientDetails = null,
}) {
  const [taskData, setTaskData] = useState(taskState);
  const [needVerifyUser, setNeedVerifyUser] = useState(false); // for toggle action to display verified user's
  const [relatedTaskData, setRelatedTaskData] = useState({
    ...taskState,
    labelDetails: [],
    task_start_date: moment().valueOf(),
    task_end_date: moment().valueOf(),
  });
  const [editTaskIndex, setEditTaskIndex] = useState(null); //used for tracing the currently editing task's index.
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAddRowActive, setIsAddRowActive] = useState(false); // to display related task only if the inline task add section appear
  const { makeAlert } = useContext(GlobalContext);
  const navigate = useNavigate();
  const currentUser = useUser().id;
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [validationData, setValidationData] = useState([]); //state for add tags visibility
  const [templateSearch, setTemplateSearch] = useState('');
  const [labelSearch, setLabelSearch] = useState('');
  const [clientSearch, setClientSearch] = useState('');
  const debouncedClient = useDebounce(clientSearch, 500);

  const [userSearch, setUserSearch] = useState(''); // state for  user list search
  const debouncedLabel = useDebounce(labelSearch, 500);
  const debouncedTemplate = useDebounce(templateSearch, 500);
  const debouncedUser = useDebounce(userSearch, 500);
  const [expandAll, setExpandAll] = useState(false);

  //
  // Handle assignee changes from both forms
  const handleAssigneeChange = useCallback((newAssignee) => {
    setTaskData((prev) => ({
      ...prev,
      assigned_user: newAssignee,
      assignee_id: newAssignee.id,
    }));
  }, []);
  // Initial setup and handling assignTo prop changes
  //
  useEffect(() => {
    if (assignTo && (!taskData.assignee_id || taskData.assignee_id != parseInt(assignTo))) {
      handleAssigneeChange({
        id: assignTo,
        ...defaultAssignee,
      });
      setIsAddRowActive(false);
    }
  }, [assignTo, defaultAssignee, handleAssigneeChange]);

  useEffect(() => {
    if (
      customDates?.task_start_date &&
      customDates?.task_end_date &&
      moment(customDates?.task_start_date).valueOf() > moment().valueOf()
    ) {
      setTaskData((prev) => ({
        ...prev,
        task_start_date: moment(customDates?.task_start_date).valueOf(),
        task_end_date: moment(customDates?.task_end_date).valueOf(),
      }));
    }
  }, [customDates]);
  const { globalSettings } = useContext(GlobalContext);

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels.some((label) => label.default_name === default_name)
    );
  };

  // position to get the current template
  const { data: templateData, error: templateFetchError } = useQuery({
    queryKey: ['templateList', debouncedTemplate, projectID, defaultTemplate],
    queryFn: () =>
      templateService.getTemplateList({
        searchKey: debouncedTemplate,
        select: ['id', 'name', 'is_default'],
        project_id: projectID,
        pageVo: { sort: 'is_default', sort_order: 'DESC' },
      }),
    select: (data) => {
      const templateList = data?.data?.rows;

      // Check if the default assignee already exists in the userData array
      const isDefaultTemplatePresent = templateList.some(
        (template) => template.id === defaultTemplate?.id,
      );
      const updatedTemplateData =
        defaultTemplate && !isDefaultTemplatePresent
          ? [...templateList, defaultTemplate]
          : templateList;
      return updatedTemplateData;
    },
    refetchOnWindowFocus: false,
  });

  const { mutateAsync, isPending } = useMutation({
    // mutationKey: ['d'],
    mutationFn: (payload) => taskService.taskAdd(payload),
    onSuccess: (data) => {
      if (data?.statusCode == 200) {
        setIsSubmitted(false);
        setTaskData((prev) => ({ ...prev, template_id: taskData.template_id }));
        handleClose();
      }
    },
    networkMode: 'online',
  });

  // template selection and task state management
  const getTemplateDataById = async (item) => {
    try {
      const templateResponse = await templateService.getSingleTemplate({
        id: item?.id,
        select: templateSelectValues,
        expanded_list: true,
      });
      if (templateResponse?.data?.length > 0) {
        const templateInfo = templateResponse?.data?.[0];
        const templateTaskData = getTaskDataFromTemplate(taskData, templateInfo);

        setSelectedTemplate(templateInfo);

        delete templateTaskData.name;
        delete templateTaskData.assignee_id;

        setTaskData((prev) => ({
          ...prev,
          ...templateTaskData,
          template_id: item?.id,
          template: templateInfo,
          project_id: projectID,
          milestone_id: milestoneID,
          has_time: templateInfo.has_time,
          client_id: clientDetails,
          start_time: templateInfo.has_time
            ? startTimeData
              ? {
                  hours: getHoursFromMs(timeBased24StringTo12Milliseconds(startTimeData)),
                  minutes: getMinutesFromMs(timeBased24StringTo12Milliseconds(startTimeData)),
                  period: getPeriodFromMs(timeBased24StringTo12Milliseconds(startTimeData)),
                }
              : // : moment().format('HH:mm')
                {
                  hours: getHoursFromMs(getCurrentTimeInMilliseconds()),
                  minutes: getMinutesFromMs(getCurrentTimeInMilliseconds()),
                  period: getPeriodFromMs(getCurrentTimeInMilliseconds()),
                }
            : 0,
          end_time: templateInfo.has_time
            ? {
                hours: getHoursFromMs(getCurrentTimeInMilliseconds() + 60 * 60 * 1000),
                minutes: getMinutesFromMs(getCurrentTimeInMilliseconds() + 60 * 60 * 1000),
                period: getPeriodFromMs(getCurrentTimeInMilliseconds() + 60 * 60 * 1000),
              }
            : 0,
          verify_users_details: templateInfo?.verify_users_details,
          subtasks: templateResponse?.data?.[0]?.subtasks.map((subtask) => {
            subtask.start_date_milli = moment().valueOf();
            subtask.startDate = new Date();
            return { ...subtask };
          }),
          relatedTasks: templateResponse?.data?.[0]?.related_tasks.map((tsk) => {
            const customStartTime = floatToDayHour(tsk.custom_start_time);
            if (typeof tsk.assignee_id === 'object')
              tsk.assignee_id.label =
                tsk?.assignee_id?.first_name + ' ' + tsk?.assignee_id?.last_name;
            return {
              ...tsk,
              label: tsk?.label?.map((item) => {
                return item?.id;
              }),
              labelDetails: tsk?.label,
              custom_start_days: customStartTime.days,
              custom_start_hours: customStartTime.hours,
              note: convertHtmlToRawContent(tsk.note),
              assigned_user: tsk?.assignee_id,
              assignee_id: tsk?.assignee_id?.id,
              project_id: projectID,
              milestone_id: milestoneID,
              client_id: clientDetails,
              task_start_date: moment().valueOf(),
              task_end_date: moment().valueOf(),
              verifying_users: templateData?.verifying_users ?? [],
              subtasks: tsk?.subtasks.map((subtask) => {
                subtask.start_date_milli = moment().valueOf();
                subtask.startDate = new Date();
                return { ...subtask };
              }),
            };
          }),
        }));
      }
    } catch (e) {
      makeAlert(e?.message);
    }
  };

  const getDefaultTemplateAndProcessData = async (item) => {
    const templateResponse = await templateService.getSingleTemplate({
      id: item?.id,
      select: templateSelectValues,
      expanded_list: true,
    });
    if (templateResponse?.data?.length > 0) {
      const templateInfo = templateResponse?.data?.[0];
      const templateTaskData = getTaskDataFromTemplate(taskData, templateInfo);
      setSelectedTemplate(templateInfo);
      delete templateTaskData.name;
      delete templateTaskData.assignee_id;

      setTaskData((prev) => ({
        ...prev,
        ...templateTaskData,
        template_id: item?.id,
        template: templateInfo,
        project_id: projectID,
        milestone_id: milestoneID,
        client_id: clientDetails,
        verify_users_details: templateInfo?.verify_users_details,
        has_time: templateInfo.has_time,
        start_time: templateInfo.has_time
          ? startTimeData
            ? {
                hours: getHoursFromMs(timeBased24StringTo12Milliseconds(startTimeData)),
                minutes: getMinutesFromMs(timeBased24StringTo12Milliseconds(startTimeData)),
                period: getPeriodFromMs(timeBased24StringTo12Milliseconds(startTimeData)),
              }
            : {
                hours: getHoursFromMs(getCurrentTimeInMilliseconds()),
                minutes: getMinutesFromMs(getCurrentTimeInMilliseconds()),
                period: getPeriodFromMs(getCurrentTimeInMilliseconds()),
              }
          : 0,
        end_time: templateInfo.has_time
          ? {
              hours: getHoursFromMs(getCurrentTimeInMilliseconds() + 60 * 60 * 1000),
              minutes: getMinutesFromMs(getCurrentTimeInMilliseconds() + 60 * 60 * 1000),
              period: getPeriodFromMs(getCurrentTimeInMilliseconds() + 60 * 60 * 1000),
            }
          : 0,
        subtasks: templateResponse?.data?.[0]?.subtasks.map((subtask) => {
          subtask.start_date_milli = moment().valueOf();
          subtask.startDate = new Date();
          return { ...subtask };
        }),
        relatedTasks: templateResponse?.data?.[0]?.related_tasks.map((tsk) => {
          const customStartTime = floatToDayHour(tsk.custom_start_time);
          if (typeof tsk.assignee_id === 'object')
            tsk.assignee_id.label =
              tsk?.assignee_id?.first_name + ' ' + tsk?.assignee_id?.last_name;
          return {
            ...tsk,
            label: tsk?.label?.map((item) => {
              return item?.id;
            }),
            label_details: tsk?.label,
            custom_start_days: customStartTime.days,
            custom_start_hours: customStartTime.hours,
            note: convertHtmlToRawContent(tsk.note),
            assigned_user: tsk?.assignee_id,
            assignee_id: tsk?.assignee_id?.id,
            project_id: projectID,
            milestone_id: milestoneID,
            task_start_date: moment().valueOf(),
            task_end_date: moment().valueOf(),
            verifying_users: templateData?.verifying_users ?? [],
            subtasks: tsk?.subtasks?.map((subtask) => {
              subtask.start_date_milli = moment().valueOf();
              subtask.startDate = new Date();
              return { ...subtask };
            }),
          };
        }),
      }));
    }
    return templateResponse;
  };

  const { isLoading: isDefaultTemplateLoading } = useQuery({
    queryKey: [queryKey, defaultTemplateId],
    queryFn: () => {
      const defaultTemplateData =
        templateData.length > 1
          ? templateData?.find((item) =>
              defaultTemplateId && !isNaN(defaultTemplateId)
                ? parseInt(item?.id) === parseInt(defaultTemplateId)
                : item?.is_default == true,
            )
          : templateData?.[0];
      if (defaultTemplateData) {
        return getDefaultTemplateAndProcessData(defaultTemplateData);
      } else {
        return null;
      }
    },
    enabled: selectDefaultTemplateIfPresent && templateData && Array.isArray(templateData),
    // && !selectedTemplate,
  });

  // for get settings label value and subtask value - get values by context API
  const isMenuLabelOn = getLabelIsActive();

  const isMenuSubTaskOn = getSubTaskIsActive();

  // to fetch the label list
  const {
    data: labelData,
    error: labelFetchError,
    refetch: refetchLabel,
  } = useQuery({
    queryKey: ['label', debouncedLabel],
    queryFn: () =>
      labelService.labelList({
        searchKey: debouncedLabel,
        select: labelSelectValues,

        pageVo: { noOfItems: 30 },
      }),
    select: (data) => {
      const response = data?.data?.rows?.map((item) => {
        return { value: item.id, label: item.name, ...item };
      });
      return response;
    },
    enabled: isMenuLabelOn,
  });

  // getUsers--
  const { data: userData, error: assignUserFetchError } = useQuery({
    queryKey: ['user-list', projectID, selectedTemplate?.id, debouncedUser],
    queryFn: () =>
      userService.getPermittedUser({
        searchKey: debouncedUser,
        select: taskAssignUserSelect,
        action: USER_LIST_ACTIONS.TASK_ADD,
        ...(projectID ? { project_id: projectID } : {}),
        ...(selectedTemplate?.id ? { template_id: selectedTemplate?.id } : {}),
      }),
    select: (data) => {
      const userData = data?.data?.rows?.map((item) => {
        return {
          value: item.id,
          label: item.first_name + ' ' + item.last_name,
          id: item.id,
          ...item,
        };
      });
      const updatedDefaultUser =
        defaultAssignee && !userSearch
          ? {
              value: defaultAssignee.id,
              label: defaultAssignee.first_name + ' ' + defaultAssignee.last_name,
              id: defaultAssignee.id,
              ...defaultAssignee,
            }
          : null;
      // Check if the default assignee already exists in the userData array
      const isDefaultUserPresent = userData.some((user) => user.id === defaultAssignee?.id);
      const updatedUserData =
        updatedDefaultUser && !isDefaultUserPresent ? [...userData, updatedDefaultUser] : userData;
      return updatedUserData;
    },
    enabled: selectedTemplate?.id ? true : false,
  });

  // Client list
  const { data: clientList, error: clientError } = useQuery({
    queryKey: ['clientListForTask', debouncedClient],
    queryFn: () => clientService.clientList({ searchKey: debouncedClient, select: ['id', 'name'] }),
    select: (data) => {
      return data?.data?.rows;
    },
    enabled:
      hasExist(MENU.contact.default_name) &&
      Boolean(selectedTemplate) &&
      selectedTemplate?.validation_fields?.client_id != VALIDATION_VALUES.HIDE,
  });

  // getUsers-- verify
  const { data: verifyUserData, error: verifyUserFetchError } = useQuery({
    queryKey: ['user-list-verify'],
    queryFn: () =>
      userService.getPermittedUser({
        select: taskAssignUserSelect,
        action: USER_LIST_ACTIONS.TASK_VERIFY,
      }),
    select: (data) => {
      const userData = data?.data?.rows?.map((item) => {
        return {
          value: item.id,
          label: item.first_name + ' ' + item.last_name,
          id: item.id,
          ...item,
        };
      });
      return userData;
    },
  });

  // handles the related task details
  const handleRelatedTaskAdd = (setIsAddRowVisible, setIsAddRowActive) => {
    //Means edit
    if (editTaskIndex != null && editTaskIndex <= taskData.relatedTasks.length) {
      const updatedRelatedTask = taskData?.relatedTasks?.map((item, index) => {
        if (index === editTaskIndex) {
          return { ...relatedTaskData };
        }
        return { ...item };
      });
      setTaskData((prev) => ({
        ...prev,
        relatedTasks: updatedRelatedTask,
      }));
      setEditTaskIndex(null);
      return;
    }
    // Else add
    setTaskData((prev) => ({
      ...prev,
      relatedTasks: [...prev.relatedTasks, relatedTaskData],
    }));
    setRelatedTaskData(taskState);
    setIsAddRowVisible(false);
    setIsAddRowActive(false);
  };
  // clear error and submission
  const errorTimeout = () => {
    setTimeout(() => {
      setIsSubmitted(false);
      setValidationData([]); // error fields clearing
    }, 3000);
  };

  // submission having validation and add task  /  error display if any
  const handleSubmit = async (setIsAddRowVisible, setIsAddRowActive) => {
    const taskDataWithProject = projectID
      ? { ...taskData, project_id: projectID }
      : { ...taskData };
    const finalTaskData = milestoneID
      ? { ...taskDataWithProject, milestone_id: milestoneID }
      : { ...taskDataWithProject, milestone_id: null };
    if (taskData?.expected_hours || taskData?.expected_minutes) {
      finalTaskData.expected_hours = getHours(taskData); // get hours by hours and minutes
    }
    if (taskData?.client_id) {
      finalTaskData.client_id = parseInt(taskData?.client_id?.id); // get hours by hours and minutes
    }
    if (needVerifyUser && taskData?.verifying_users?.length == 0) {
      makeAlert('Please select verifying users');
      errorTimeout();
      // return;
    }
    if (taskData?.verifying_users?.length > 0) {
      finalTaskData.verifying_users = taskData?.verifying_users?.map((item) => item?.id);
    }
    if (taskData?.start_time && taskData?.has_time) {
      const milliSecondTime = convertTimeIn12ToMillisecond(
        taskData?.start_time?.hours,
        taskData?.start_time?.minutes,
        taskData?.start_time?.period,
      );
      finalTaskData.start_time = milliSecondTime ? milliSecondTime : 0;
    }
    if (taskData?.end_time && taskData?.has_time) {
      const milliSecondTime = convertTimeIn12ToMillisecond(
        taskData?.end_time?.hours,
        taskData?.end_time?.minutes,
        taskData?.end_time?.period,
      );
      finalTaskData.end_time = milliSecondTime ? milliSecondTime : 0;
    }
    // validation for start and end time
    if (taskData?.has_time && finalTaskData?.start_time > finalTaskData?.end_time) {
      makeAlert('Please choose valid start and end time');
      return;
    }
    console.log('clientDaaaa', finalTaskData);

    // validation for date
    const isInvalidDate =
      moment(taskData?.task_end_date).startOf('day').isBefore(moment().startOf('day')) ||
      moment(taskData?.task_start_date).startOf('day').isBefore(moment().startOf('day'));
    if (taskData?.task_start_date && taskData?.task_end_date && isInvalidDate) {
      makeAlert('Please choose valid date');
      errorTimeout();
      return;
    }

    if (!selectedTemplate) {
      makeAlert('Please select template');
      setTimeout(scrollToErrorElement);
      errorTimeout();
      return;
    }
    const validationError = validateTaskWithTemplateTest(selectedTemplate?.validation_fields, [
      finalTaskData,
    ]); // checking which fields are required for task and shows error - template
    if (!validationError?.isValid) {
      setValidationData(validationError?.requiredFields);
      if (validationError?.requiredFields?.includes('verifying_users')) {
        makeAlert(taskValidationConstant.VERIFY_USERS_REQUIRED);
      }
      // if (validationError?.requiredFields?.includes('client_id')) {
      //   makeAlert(taskValidationConstant.CONTACT_REQUIRED);
      // }
      errorTimeout();
      return;
    }
    if (okr_Parent_id) {
      finalTaskData.okr_parent_id = okr_Parent_id;
    }
    if (taskData)
      if (typeof taskData?.note === 'object') {
        finalTaskData.note = draftToHtml(taskData?.note);
      }

    const isValidHour = validateHours(
      taskData?.task_start_date,
      taskData?.task_end_date,
      finalTaskData.expected_hours,
    ); // to validate the hours according to the start - end dates
    if (isValidHour) {
      let time = parseFloat(finalTaskData.expected_hours);
      finalTaskData.expected_hours = time;
    } else {
      makeAlert(taskValidationConstant.INVALID_EXPECTED_TIME);
      errorTimeout();
      return;
    }

    let hasValidationError = false; // for related task validation

    let updatedRelatedTasks = finalTaskData.relatedTasks.map((relatedTask) => {
      let updatedTask = { ...relatedTask }; // Create a shallow copy to avoid mutating the original object

      updatedTask.verifying_users = finalTaskData?.verifying_users;

      if (typeof updatedTask?.note === 'object') {
        const converted = draftToHtml(updatedTask?.note);
        updatedTask.note = converted;
      }

      updatedTask.expected_hours = getHours(updatedTask); // get hours by hours and minutes

      const validationErrorRelatedTask = validateTaskWithTemplateTest(
        selectedTemplate?.validation_fields,
        [updatedTask],
      );

      if (!validationErrorRelatedTask?.isValid) {
        let errorFields = validationErrorRelatedTask?.requiredFields?.map((item) => {
          return taskErrorKeyValues[item];
        });
        makeAlert(`${errorFields} required in related task "${updatedTask?.name}"`); // make alert for which fields are required for individual related task
        errorTimeout();
        hasValidationError = true; // Set the flag to true if validation fails
        return updatedTask; // Return early for this task
      }

      updatedTask.task_start_date = taskData.task_start_date;
      updatedTask.task_end_date = taskData.task_end_date; // review needed

      if (typeof updatedTask.assigned_user === 'object') {
        updatedTask.assignee_id = updatedTask.assigned_user.id;
      }

      return updatedTask;
    });
    if (hasValidationError) {
      return; // Exit the handleSubmit function if any related task validation fails
    }

    finalTaskData.relatedTasks = updatedRelatedTasks;

    delete finalTaskData.expected_minutes;
    setIsSubmitted(true);

    try {
      await mutateAsync(finalTaskData);
      if (componentMode == formMode.INLINE) {
        setIsAddRowVisible(false);
        setIsAddRowActive(false);
      }
      // after task add, navigation to pages
      const taskForLoggedUser = parseInt(finalTaskData?.assignee_id) === parseInt(currentUser);
      const isMyTaskPresent = hasExist(MENU?.myResponsibility?.default_name);
      if (afterTaskAddFn) {
        afterTaskAddFn();
      } else {
        if (!okr_Parent_id && !projectID && !defaultTemplateId && !clientDetails?.id)
          navigate(
            `/${RT.TASK_LIST}?status=todo&pageNo=1&list_type=${
              taskForLoggedUser && isMyTaskPresent ? 'my-task' : 'team-task'
            }${taskForLoggedUser && isMyTaskPresent ? `&user_id=${currentUser}` : ''}`,
          );
        if (!okr_Parent_id && projectID && !defaultTemplateId && !clientDetails?.id)
          if (milestoneID) {
            navigate(
              `/${RT.PROJECT}/${projectID}/${RT.PROJECT_TASK_LIST}?status=todo&pageNo=1&milestone_id=${milestoneID}&&list_type=${TaskListTypeConst.Project}
        `,
            );
          } else {
            navigate(
              `/${RT.PROJECT}/${projectID}/${RT.PROJECT_TASK_LIST}?status=todo&pageNo=1&list_type=${TaskListTypeConst.Project}`,
            );
          }
        if (!okr_Parent_id && !projectID && defaultTemplateId && !clientDetails?.id)
          navigate(
            `/${RT.TEMPLATE}/${defaultTemplateId}?status=todo&pageNo=1&list_type=${TaskListTypeConst.Template}`,
          );
        if (!okr_Parent_id && !projectID && !defaultTemplateId && clientDetails?.id)
          navigate(
            `/${RT.CLIENT}/${clientDetails?.id}/${RT.CLIENT_TASK_LIST}?status=todo&list_type=${TaskListTypeConst.Template}`,
          );
      }
    } catch (e) {
      console.log({ e });
      makeAlert(e?.message ?? 'Server is busy');
      console.log(e);
      setIsSubmitted(false);
    } finally {
      clearField();
      errorTimeout();
    }
  };
  /***
   * For deleting the related task
   * @param index - The index of the task to be deleted
   */
  const handleRelatedTaskDelete = (index) => {
    // Get the task to be deleted
    const taskToBeDeleted = taskData.relatedTasks[index];
    // If task depends on other task, user can't delete it.
    const isTaskDependsOn = taskData.relatedTasks.some(
      (task) => task.preceding_task_id === taskToBeDeleted.tempId,
    );
    if (isTaskDependsOn) {
      makeAlert(TEMPLATE_ERROR_MSGS.TASK_DEPENDS_ON_OTHER_TASK);
      return;
    }

    // Otherwise delete the task
    setTaskData((prev) => ({
      ...prev,
      relatedTasks: prev.relatedTasks.filter((task) => task.tempId !== taskToBeDeleted.tempId),
    }));
  };
  /***
   * To scroll to the first found error element. 'help-block' class is considered as the error element. It will scroll to the first found parent of help-block class.
   */
  const scrollToErrorElement = () => {
    const element = document.querySelector('.has-error');
    if (!element) {
      return;
    }
    const parentElement = element.parentElement;
    parentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  // to update related task data in the list
  const updateRelatedTaskFromSingleView = (updatedValues, idx) => {
    setTaskData((prev) => {
      return {
        ...prev,
        relatedTasks: prev.relatedTasks.map((item, index) => {
          if (index === idx) {
            if (typeof updatedValues === 'function') {
              return updatedValues(item);
            }
            return { ...item, ...updatedValues };
          }
          return item;
        }),
      };
    });
  };

  // to reset all fields
  const clearField = () => {
    setTaskData((prevTaskData) => ({
      ...taskState,
      template_id: prevTaskData.template_id,
      template: prevTaskData.template,
      assigned_user: defaultAssignee,
      assignee_id: assignTo,
      project_id: projectID,
      milestone_id: milestoneID,
    }));
    setSelectedTemplate((prevTemplate) => prevTemplate);
  };
  // onCancel add task
  const onCancel = () => {
    setRelatedTaskData(taskState);
    setEditTaskIndex(null);
  };
  //on edit related task data ---
  const onEditRelatedTask = (index) => {
    setEditTaskIndex(index);
    setRelatedTaskData(taskData?.relatedTasks[index]);
  };

  //on toggle inactive press remove the selected verify users
  const handleVerifyUserToggle = (toggleValue) => {
    setNeedVerifyUser(toggleValue);
    // if (!toggleValue) {
    //   setTaskData({ ...taskData, verifying_users: [] });
    // }
  };

  const relatedTableContainerRef = useRef(null);

  const [, setHasScrollbar] = useState(false);

  useEffect(() => {
    const checkScrollbar = () => {
      if (relatedTableContainerRef.current) {
        setHasScrollbar(
          relatedTableContainerRef.current.scrollHeight >
            relatedTableContainerRef.current.clientHeight,
        );
      }
    };

    checkScrollbar();

    window.addEventListener('resize', checkScrollbar);
    return () => {
      window.removeEventListener('resize', checkScrollbar);
    };
  }, [relatedTableContainerRef.current]);

  return (
    <section>
      <ErrorComponent
        error={
          verifyUserFetchError?.message ||
          assignUserFetchError?.message ||
          labelFetchError?.message ||
          templateFetchError?.message ||
          clientError?.message
        }
      />
      {from !== TASK_TABS.CALENDAR && (
        <div
          className={`d-flex align-items-center task-modal-header justify-content-between ${
            showHeaderAndFooter ? 'd-none' : ''
          }`}
        >
          <div className='d-flex align-items-center gap-3'>
            <span className='font-16'>Create Task</span>
            <div className='pointer'>
              <SearchableQuerySelect
                queryKey={'templateList'}
                queryFn={templateService.getTemplateList}
                getFunctionParams={(searchKey) => ({
                  searchKey,
                  select: ['id', 'name', 'is_default'],
                  project_id: projectID,
                  pageVo: { sort: 'is_default', sort_order: 'DESC' },
                })}
                isCustomFunction
                initialValue={templateData}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={selectedTemplate}
                onChange={(selectedOptions) => {
                  setNeedVerifyUser(false);
                  getTemplateDataById(selectedOptions);
                }}
                styles={customTemplateSelectBoxStyles}
                isDisabled={templateSelectionDisabled}
                placeholder='Select Template'
                className={`mt-1 select-box  ${isSubmitted && !selectedTemplate && 'has-error'} `}
              />
              <section className='position-absolute'>
                <ErrorBlock
                  errorMsg={'Please Select Template'}
                  hasError={isSubmitted && !selectedTemplate}
                />
              </section>
            </div>
          </div>
          <X onClick={handleClose} className='ptr' style={{ marginRight: -6 }} color='#87909E' />
        </div>
      )}
      {!isDefaultTemplateLoading ? (
        <div>
          {/* <hr className={`mx-4 ${showHeaderAndFooter && 'd-none'}`} /> */}
          <div
            className={` ${
              componentMode == formMode.INLINE ? ' inline-container' : 'task-add-body'
            }`}
          >
            {componentMode == formMode.FORM ? (
              <TaskAddFormSection
                key={'task-description'}
                templateData={selectedTemplate}
                taskData={taskData}
                setTaskData={setTaskData}
                userList={userData}
                verifyUserList={verifyUserData}
                labelList={labelData}
                isSubmitted={isSubmitted}
                validationData={validationData}
                setNeedVerifyUser={handleVerifyUserToggle}
                needVerifyUser={needVerifyUser}
                isMenuLabelOn={isMenuLabelOn}
                isMenuSubTaskOn={isMenuSubTaskOn}
                onAssigneeChange={handleAssigneeChange}
                tableRef={tableRef}
                clientList={clientList}
                isProjectExistClient={clientDetails?.id ? true : false}
                labelRefetch={() => refetchLabel()}
                hasExist={hasExist}
              />
            ) : (
              <div
                className='d-flex align-items-baseline gap-2 position-relative'
                style={{ marginLeft: '15px' }}
              >
                <ModifiedInlineTaskAdd
                  key={projectID ? projectID : selectedTemplate?.id}
                  taskData={taskData}
                  setTaskData={setTaskData}
                  clientDetails={clientDetails}
                  templateData={selectedTemplate}
                  onAdd={(setIsAddRowVisible, setIsAddRowActive) =>
                    handleSubmit(setIsAddRowVisible, setIsAddRowActive)
                  }
                  userList={userData}
                  tableRef={tableRef}
                  labelList={labelData}
                  onActiveForm={false}
                  onCancel={clearField}
                  isAddRowActive={isAddRowActive}
                  setIsAddRowActive={setIsAddRowActive}
                  isMain
                  isLoading={isSubmitted}
                  verifyUserData={verifyUserData}
                  projectID={projectID}
                  templateSelectionDisabled={templateSelectionDisabled}
                  setNeedVerifyUser={setNeedVerifyUser}
                  isSubmitted={isSubmitted}
                  templateList={templateData}
                  setLabelSearch={setLabelSearch}
                  labelSearch={labelSearch}
                  setSearch={setUserSearch}
                  setTemplateSearch={setTemplateSearch}
                  validationData={validationData}
                  isMenuLabelOn={isMenuLabelOn}
                  isMenuSubTaskOn={isMenuSubTaskOn}
                  onAssigneeChange={handleAssigneeChange}
                  clientList={clientList}
                  setClientSearch={setClientSearch}
                />
              </div>
            )}
            {taskData?.relatedTasks?.length > 0 && (
              <div
                ref={relatedTableContainerRef}
                className={`flex-1 ${
                  componentMode == formMode.INLINE
                    ? ' mx-4  inline-container'
                    : 'template-container  mx-2'
                }`}
                hidden={componentMode == formMode.INLINE && !isAddRowActive}
              >
                <TaskAddRelatedTaskList>
                  <div>
                    <div className='d-flex align-items-center gap-2'>
                      <div
                        className={`sub-title mb-1${componentMode == formMode.INLINE && ' d-flex'}`}
                      >
                        Related Tasks
                      </div>
                      <ToolTip tooltipText={expandAll ? 'Collapse All' : 'Expand All'} isModern>
                        <div style={{ marginLeft: 8 }}>
                          <ToggleButton
                            key={expandAll}
                            isChecked={expandAll ? 1 : 0}
                            handleToggle={() => setExpandAll(!expandAll)}
                            size={28}
                            disabled={false}
                          />
                        </div>
                      </ToolTip>
                    </div>
                    {taskData?.relatedTasks?.map((relatedTask, idx) => {
                      return (
                        <SingleRelatedTaskPreview
                          allRelatedTask={taskData?.relatedTasks}
                          templateData={selectedTemplate}
                          key={idx}
                          index={idx}
                          setUserSearch={setUserSearch}
                          relatedTaskDetails={relatedTask}
                          onEdit={() => {
                            onEditRelatedTask(idx);
                          }}
                          onDelete={() => {
                            handleRelatedTaskDelete(idx);
                          }}
                          userList={userData}
                          onSubmit={(updatedValues) =>
                            updateRelatedTaskFromSingleView(updatedValues, idx)
                          }
                          setLabelSearch={setLabelSearch}
                          labelList={labelData}
                          onActiveForm={true}
                          onCancel={onCancel}
                          isRelatedTask={true}
                          componentMode={componentMode}
                          isMenuLabelOn={isMenuLabelOn}
                          isMenuSubTaskOn={isMenuSubTaskOn}
                          scrollRef={relatedTableContainerRef}
                          expandAll={expandAll}
                          setExpandAll={setExpandAll}
                        />
                      );
                    })}
                  </div>
                  <div className='d-flex align-items-center w-100 gap-2 '>
                    {componentMode == formMode.INLINE && <GitMerge size={18} color='#879096' />}
                    <InlineRelatedTaskAdd
                      key={relatedTaskData?.id}
                      taskData={relatedTaskData}
                      setTaskData={setRelatedTaskData}
                      templateData={selectedTemplate}
                      onAdd={(setIsAddRowVisible, setIsAddRowActive) =>
                        handleRelatedTaskAdd(setIsAddRowVisible, setIsAddRowActive)
                      }
                      setLabelSearch={setLabelSearch}
                      userList={userData}
                      labelList={labelData}
                      onActiveForm={editTaskIndex !== null}
                      onCancel={onCancel}
                      isRelatedTask={true}
                      projectID={projectID}
                      isEdit={editTaskIndex !== null}
                      editExisting={editTaskIndex !== null}
                      setSearch={setUserSearch}
                      setEditTaskIndex={setEditTaskIndex}
                      setUserSearch={setUserSearch}
                      labelSearch={labelSearch}
                      userSearch={userSearch}
                      isUpdateButtonVisible
                      isMenuLabelOn={isMenuLabelOn}
                      isMenuSubTaskOn={isMenuSubTaskOn}
                      tableRef={relatedTableContainerRef}
                    />
                  </div>
                </TaskAddRelatedTaskList>
              </div>
            )}
          </div>
          <div
            className={`mx-4 mb-3 mt-30px px-30px d-flex top-light-shadow justify-content-end align-items-center btn-container ${
              showHeaderAndFooter && 'd-none'
            }`}
          >
            <div className='d-flex gap-2'>
              <Button.Container
                className='cancel-btn'
                isDisabled={isSubmitted}
                handleOk={handleClose}
              >
                <Button.Title title='Cancel' />
              </Button.Container>
              <Button.Container type='submit' handleOk={handleSubmit} isDisabled={isPending}>
                <Button.Title />
                <Button.Loading color='#FFF' isLoading={isPending} size={20} />
              </Button.Container>
            </div>
          </div>{' '}
        </div>
      ) : (
        componentMode != formMode.INLINE && (
          <div
            style={{ height: '75vh' }}
            className=' d-flex justify-content-center align-items-center'
          >
            <LoadingIcon size={60} />
          </div>
        )
      )}
    </section>
  );
}
