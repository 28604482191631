import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { X } from 'lucide-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../_components';
import ToolTip from '../_components/Tooltip/ToolTip';
import { RT } from '../_constants';
import { FILE_TYPE } from '../_constants/filesystem.constant';
import useDebounce from '../_helpers/useDebounce';
import { miscService } from '../_services';
import { TaskListTypeConst } from '../BoardView/BoardView.constant';
import { FileDetails } from '../FileSystem/FileDetails';
import { Search } from '../Utils/SvgIcons';
import { TAB_VALUE_CONST } from './GlobalSearchHelperFunction';
import { GlobalSearchResult } from './GlobalSearchResult';
import { GlobalSearchTabs } from './GlobalSearchTabs';

export const GlobalSearch = ({ setIsOpen }) => {
  const [scrollFlag, setScrollFlag] = useState({ isTopReached: true, isBottomReached: false });
  const [activeTab, setActiveTab] = useState(TAB_VALUE_CONST.All);
  const [searchKey, setSearchKey] = useState('');
  const [fileDetailsVisible, setFileDetailsVisible] = useState(null);

  const debouncedSearch = useDebounce(searchKey, 500);
  const navigate = useNavigate();
  const handleScroll = (event) => {
    // eslint-disable-next-line no-unused-vars
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (scrollTop === 0) {
      setScrollFlag({ isTopReached: true });
    } else {
      setScrollFlag({ isBottomReached: false, isTopReached: false });
    }
  };

  const {
    data: searchResult,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['global-search', debouncedSearch, activeTab],
    queryFn: () =>
      miscService.globalSearchResultList({
        searchKey: debouncedSearch,
        searchTable: activeTab.name,
        pageVo: { pageNo: 1, noOfItems: 8 },
      }),
    select: (data) => data?.data,
    enabled: Boolean(searchKey),
    placeholderData: keepPreviousData,
  });

  const getNavigation = (tab, data) => {
    if (tab === TAB_VALUE_CONST.USER.value) {
      navigate(`/${RT.MY_TEAM}/${data.id}`);
      setIsOpen(false);
    } else if (tab === TAB_VALUE_CONST.PROJECT.value) {
      navigate(
        `/${RT.PROJECT}/${data?.id}/${RT.PROJECT_TASK_LIST}?status=${
          data.navigation_id ?? 'in-progress'
        }&list_type=${TaskListTypeConst.Project}`,
      );
      setIsOpen(false);
    } else if (tab === TAB_VALUE_CONST.FILE.value) {
      setFileDetailsVisible(data);
    } else if (tab === TAB_VALUE_CONST.TASK.value) {
      navigate(
        `/${RT.TASK}/${data?.id}?list_type=${TaskListTypeConst.TeamTask}&&tab=subtask&&task_status=${data?.status}`,
      );
      setIsOpen(false);
    } else if (tab === TAB_VALUE_CONST.CHAT.value) {
      let url = `/${RT.CHAT}?tab=all&&id=${data?.id}`;
      navigate(url);
      setIsOpen(false);
    } else if (tab === TAB_VALUE_CONST.UPDATE.value) {
      navigate(
        `/${RT.TASK}/${data?.id}?list_type=${TaskListTypeConst.TeamTask}&&tab=subtask&&task_status=${data?.related_info?.status}`,
      );
      setIsOpen(false);
    }
  };

  return (
    <div className='position-relative' style={{ minHeight: '80vh' }}>
      <div style={{ position: 'absolute', top: 7, right: 15, cursor: 'pointer' }}>
        <ToolTip tooltipText={'Close'} isModern>
          <X size={15} onClick={() => setIsOpen(false)} />
        </ToolTip>
      </div>
      <div
        className='global-search-header'
        style={{
          boxShadow: scrollFlag.isTopReached ? '' : '0 6px 5px -5px #091e4229',
        }}
      >
        <div className='global-search-box'>
          <Search size={18} color='#2A2E34' />
          <input
            onChange={(event) => {
              setSearchKey(event.target.value);
            }}
            placeholder='Search...'
            type='text'
            className='bodr_none'
            value={searchKey}
            autoFocus
          />
        </div>
        <GlobalSearchTabs setActiveTab={setActiveTab} activeTab={activeTab} />
      </div>
      {!searchKey && (
        <div
          className='d-flex  align-items-center justify-content-center h-100 fz-13px gap-1 mt-5'
          style={{ color: 'var(--Second-text-color)' }}
        >
          <Search size={18} color='#2A2E34' />
          Search something....
        </div>
      )}
      {Boolean(searchKey) && (
        <div className='global-search-result' onScroll={handleScroll}>
          <GlobalSearchResult
            currentTab={activeTab.value}
            result={searchResult}
            isLoading={isLoading || isFetching}
            getNavigation={getNavigation}
          />
        </div>
      )}

      <section>
        {fileDetailsVisible?.id && (
          <Modal.Container>
            <Modal.Head containerClass={'task-add-head'}></Modal.Head>
            <Modal.View className={'file-details-popup'}>
              <FileDetails
                // setIsActivityUpdated={setIsActivityUpdated}
                isDigital={fileDetailsVisible?.file_type === FILE_TYPE.DIGITAL}
                id={fileDetailsVisible?.id}
                handleClose={(event) => {
                  if (event) {
                    event.stopPropagation();
                  }
                  setFileDetailsVisible(false);
                  // if (isActivityUpdated) {
                  //   refetch();
                  //   setIsActivityUpdated(false);
                  // }
                  // setDetailsVisibleIndex(null);
                }}
              />
            </Modal.View>
          </Modal.Container>
        )}
      </section>
    </div>
  );
};
