/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  ArrowUp,
  Calendar,
  Clock3,
  Disc2,
  Flag,
  Hourglass,
  Milestone,
  Plus,
  UserRoundCheck,
} from 'lucide-react';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import ReactSelect from 'react-select';
import useOutsideClickHandler from '../../Hook/useOutsideClickHandler';
import { UserListSelectionPopup } from '../../Project/UserListSelectionPopup';
import { formatMillisecondFeatured, GlobalContext, isEffortGreaterThanExpected } from '../../Utils';
import { ChildTreeDownIcon, OkrIcon } from '../../Utils/SvgIcons';
import { SelectableContextMenu } from '../../_components';
import CircularAvatar from '../../_components/Avatar/CircularAvatar';
import DatePicker from '../../_components/DatePicker';
import { ErrorComponent } from '../../_components/ErrorComponent';
import { Popover } from '../../_components/Popover';
import SearchableQuerySelect from '../../_components/SearchableQuerySelect';
import UserDropDown from '../../_components/UserDropDown';
import { apiConstants, MENU, USER_LIST_ACTIONS } from '../../_constants';
import { projectConstants } from '../../_constants/project.constant';
import useDebounce from '../../_helpers/useDebounce';
import { clientService, okrService, userService } from '../../_services';
import { labelService } from '../../_services/label.service';
import { milestoneService } from '../../_services/milestone.service';
import {
  customFlagSelectBoxStyles,
  customUserSelectBoxStyles,
  orderSequence,
  taskAssignUserSelect,
  taskType,
  taskValidationConstant,
} from '../taskConstants';
import { EffortsIcon, OwnerIcon, PriorityIcon, ProjectIcon, TagsIcon } from '../taskIcons';
import {
  getHours,
  getLabelIsActive,
  getPriorityByContextAPI,
  getTaskTime,
  validateHours,
} from '../taskValidation';
import {
  CustomEndTimeInput,
  CustomLabelSelectModal,
  CustomStartTimeInput,
  ProjectChangeInputs,
} from './CustomInputs';
import EditableField from './EditableField';
import HorizontalScrollMenu from './HorizontalScrollMenu';
import RequestHourInput from './RequestHourInput';
import { ActionComponent } from './TaskActions';

function TaskEditableSingleView({
  taskData,
  handleTaskUpdate,
  handleAction,
  isUpdating = { isLoad: false },
  isArchivedTask = false,
  projectBasedClientList = [],
}) {
  const options = getPriorityByContextAPI(); // to fetch the priority  label from context api
  const { makeAlert, getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  const [contextLabel, setContextLabel] = useState(false); //state for  label list visibility
  const [contextTime, setContextTime] = useState(false); //state for  label list visibility
  const [startTimeAddVisible, setStartTimeAddVisible] = useState(false); //state for  start time add
  const [endTimeAddVisible, setEndTimeAddVisible] = useState(false); //state for  end time add
  const [isProjectModal, setIsProjectModal] = useState(false); //state for  label list visibility  const [isProjectModal, setIsProjectModal] = useState(false); //state for  label list visibility

  const [labelSearch, setLabelSearch] = useState('');
  const debouncedLabel = useDebounce(labelSearch, 500);
  const isMenuLabelOn = getLabelIsActive();
  const [okrListPopup, setOkrListPopup] = useState(false); // state for  user list search
  const [okrSearch, setOkrSearch] = useState(''); // state for  user list search
  const debouncedOkrSearch = useDebounce(okrSearch, 500);

  const filteredIds = taskData?.label_details && taskData?.label_details?.map((item) => item.id);
  const { containerRef: okrListRef } = useOutsideClickHandler({
    onClose: () => {
      // setUserSearch('');
      setOkrListPopup(false);
    },
  });
  const {
    data: milestoneData,
    error,
    isLoading: milestoneLoading,
  } = useQuery({
    queryKey: ['milestoneList', taskData?.project_id],
    queryFn: () =>
      milestoneService.milestoneList({
        select: ['id', 'name', 'progress_status'],
        project_id: taskData?.project_id,
      }),
    select: (data) => {
      const response = data.data.rows;
      const currentMilestoneData = {
        id: taskData?.milestone_id,
        name: taskData?.milestone_name,
        value: taskData?.milestone_id,
        label: taskData?.milestone_name,
      };
      if (currentMilestoneData.id && !response.find((item) => item.id == currentMilestoneData.id)) {
        response.push(currentMilestoneData);
      }
      return response;
    },

    placeholderData: keepPreviousData,
    enabled: Boolean(taskData?.project_id),
  });

  // okr list
  const {
    data: okrData,
    isLoading: okrLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['okrList', debouncedOkrSearch, taskData?.okr_parent_id],
    queryFn: () =>
      okrService.OKRList({
        searchKey: debouncedOkrSearch,
        select: ['id', 'name', 'type'],
        listForTask: true,
        okr_parent_id: taskData?.okr_parent_id,
      }),
    placeholderData: keepPreviousData,
  });

  // to get label/tag list for change label/tag
  const {
    data: labelData,
    isLoading: isLabelLoading,
    // eslint-disable-next-line no-unused-vars
    error: labelLoadError,
  } = useQuery({
    queryKey: ['labelData', taskData?.template_id, debouncedLabel],
    queryFn: () =>
      labelService.labelList({
        searchKey: debouncedLabel,
        select: ['id', 'name', 'label_color', 'text_color', 'status'],
      }),
    select: (data) => {
      // to get already selected labels
      const uniqueElements =
        filteredIds?.length > 0
          ? data?.data?.rows?.filter((element) => !filteredIds.includes(parseInt(element.id)))
          : data?.data?.rows; //filtered selection unselected labels
      return uniqueElements;
    },
  });

  // Client list
  const {
    data: clientList,
    error: clientError,
    isLoading: clientLoading,
  } = useQuery({
    queryKey: ['clientListForTaskSingleView'],
    queryFn: () => clientService.clientList({ select: ['id', 'name'] }),
    select: (data) => {
      return data?.data?.rows;
    },
    enabled: projectBasedClientList?.length === 0,
  });

  // to get user list for change the owner
  const {
    data: userList,
    error: userError,
    isLoading: isUserLoading,
  } = useQuery({
    queryKey: ['userData', taskData?.id],
    queryFn: () =>
      userService.getPermittedUser({
        action: USER_LIST_ACTIONS.TASK_ADD,
        select: taskAssignUserSelect,
        ...(taskData?.project_id ? { project_id: taskData?.project_id } : {}),
        ...(taskData?.template_id && taskData?.template_id > 0
          ? { template_id: taskData?.template_id }
          : {}),
      }),
    select: (data) => {
      const userData = data?.data?.rows?.map((item) => {
        return {
          value: item.id,
          label: item.first_name + ' ' + item.last_name,
          id: item.id,
          ...item,
        };
      });
      // for showing selected user in the select box
      const currentUserData = {
        id: taskData?.assignee_details?.[0]?.id,
        value: taskData?.assignee_details?.[0]?.id,
        label:
          taskData?.assignee_details?.[0]?.first_name +
          ' ' +
          taskData?.assignee_details?.[0]?.last_name,
        ...taskData?.assignee_details?.[0],
      };
      if (!userData?.find((item) => item.id == currentUserData.id)) {
        userData.push(currentUserData);
      }
      return userData;
    },
  });

  // save action - conversion of edited data to required format and save
  // to change time to required format and save
  const onSaveTime = (editTaskData) => {
    if (editTaskData?.expected_hours) {
      const isValid = validateHours(
        taskData?.task_start_date,
        taskData?.task_end_date,
        editTaskData?.expected_hours,
      ); // to validate the hours according to the start - end dates
      if (isValid) {
        let time = parseInt(editTaskData?.expected_hours);
        editTaskData.expected_hours = time;
      } else {
        makeAlert(taskValidationConstant.INVALID_EXPECTED_TIME);
        return;
      }
    } else if (editTaskData?.expected_minutes) {
      let time = parseInt(editTaskData?.expected_minutes);
      editTaskData.expected_minutes = time;
    }
    // for converting the hours and save action
    if (editTaskData?.expected_hours || editTaskData?.expected_minutes) {
      editTaskData.expected_hours = getHours(editTaskData); // get hours by hours and minutes
    }
    if (!editTaskData?.expected_hours || editTaskData?.expected_hours <= 0) {
      editTaskData.expected_hours = taskData?.expected_hours;
    }
    handleTaskUpdate(editTaskData);
  };
  // to change date to required format and save
  const onSaveDate = (editTaskData) => {
    if (editTaskData?.task_start_date || editTaskData?.task_end_date) {
      const isValid = validateHours(
        editTaskData?.task_start_date,
        editTaskData?.task_end_date,
        taskData?.expected_hours,
      ); // to validate the hours according to the start - end dates
      if (isValid) {
        // for converting the date and save
        editTaskData.task_start_date = moment(editTaskData?.task_start_date).valueOf();
        editTaskData.task_end_date = moment(editTaskData?.task_end_date).valueOf();
      } else {
        makeAlert(taskValidationConstant.INVALID_TASK_DATES);
        return;
      }
    }
    if (!editTaskData?.task_start_date) {
      editTaskData.task_start_date = taskData?.task_start_date;
    }
    if (!editTaskData?.task_end_date) {
      editTaskData.task_end_date = taskData?.task_end_date;
    }

    handleTaskUpdate(editTaskData);
    editTaskData = {};
  };
  // to change label to required format and save
  const handleLabelUpdate = (item) => {
    let editedLabelData = filteredIds?.length > 0 ? [...filteredIds, item] : [item];
    handleTaskUpdate({ label: editedLabelData });
  };

  // to remove label
  const handleLabelRemove = (item) => {
    let editedLabelData = filteredIds.filter((id) => id != item);
    handleTaskUpdate({ label: editedLabelData });
  };

  const isExceeded = isEffortGreaterThanExpected(
    taskData?.expected_hours,
    taskData?.sum_of_efforts,
  );

  const handleOkrSelectionSubmit = (selectedOkr) => {
    const okr = selectedOkr[0];
    handleTaskUpdate({ okr_parent_id: okr?.id });
  };
  const { globalSettings } = useContext(GlobalContext);

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels.some((label) => label.default_name === default_name)
    );
  };

  return (
    <div>
      <ErrorComponent
        error={
          error?.message || labelLoadError?.message || userError?.message || clientError?.message
        }
      />
      <div className='task-heading-container'>
        <span className='me-1'>
          {taskData?.parent_id && parseInt(taskData?.parent_id) ? (
            <ChildTreeDownIcon height={23} width={20} />
          ) : (
            ''
          )}
        </span>
        <EditableField
          onSave={(val) => handleTaskUpdate(val)}
          key={taskData?.name}
          name='name'
          type='input'
          placeholder='Type Task Name'
          isEditable={taskData?.edit_fields?.includes('OKR Name')}
          data={{ name: taskData?.name }}
          inputClassName='taskname-input w-100 form-control'
        >
          <div className='font-18' role={taskData?.edit_fields?.includes('OKR Name') && 'button'}>
            {taskData?.name}
          </div>
        </EditableField>
        {taskData?.status_actions?.length > 0 && (
          <ActionComponent
            actionData={taskData?.status_actions}
            onSave={(val) => {
              if (taskData?.status) {
                handleAction({ ...val, status: taskData.status });
              } else {
                handleAction(val);
              }
            }}
            isLoading={isUpdating}
            isArchivedTask={isArchivedTask}
          />
        )}
      </div>
      <div className='task-single-view-items-card row'>
        <div className='col-md-6 '>
          {taskData?.visible_fields?.includes('Project') &&
            hasExist(MENU.project.default_name) &&
            (taskData?.edit_fields?.includes('Project') || taskData?.project_id != null) && (
              <div className='task-single-view-item-container position-relative'>
                <div className='task-single-menu-item gap-1 align-items-center'>
                  <div className='task-single-menu-icon'>
                    <ProjectIcon />
                  </div>
                  {LABEL_OF_PROJECT}
                </div>
                <div
                  className={`task-single-menu-value  ${
                    taskData?.edit_fields?.includes('Project') ? 'underline-hover' : ''
                  } `}
                  role={taskData?.edit_fields?.includes('Project') && 'button'}
                  onClick={() =>
                    taskData?.edit_fields?.includes('Project') && setIsProjectModal(true)
                  }
                >
                  {taskData?.project_name ?? 'Select '}
                </div>
                {isProjectModal && (
                  <Popover.PopoverItem
                    onClose={() => makeAlert('Please close the box', 'error')}
                    positionTop={(top) => -top}
                    positionLeft={() => 0}
                  >
                    <ProjectChangeInputs
                      onSubmit={(val) => {
                        handleTaskUpdate(val);
                      }}
                      userId={taskData?.assignee_details?.[0]?.id}
                      setIsInputVisible={setIsProjectModal}
                      initialValue={{
                        project_id: taskData?.project_id,
                        template_id: taskData?.template_id,
                        project_name: taskData?.project_name,
                        template_name: taskData?.template_name,
                        status_group_id: taskData?.status_group_id,
                        status_group_item_id: taskData?.status_group_item_id,
                      }}
                      isHeadInvisible
                      project_id
                    />
                  </Popover.PopoverItem>
                )}
              </div>
            )}
          {/* milestone selection */}
          {taskData?.project_type != 0 &&
            taskData?.edit_fields?.includes('Milestone') &&
            hasExist(MENU.project.default_name) && (
              <div className='task-single-view-item-container '>
                <div className='task-single-menu-item gap-1 align-items-center'>
                  <div className='task-single-menu-icon'>
                    <Milestone />
                  </div>
                  {taskData?.project_type === projectConstants.MILESTONE ? 'Milestone' : 'Sprint'}
                </div>
                <div className='task-single-menu-value underline-hover'>
                  <SearchableQuerySelect
                    name='milestone_id'
                    getOptionLabel={(option) => {
                      return option?.name;
                    }}
                    isLoading={milestoneLoading}
                    getOptionValue={(option) => {
                      return option?.id;
                    }}
                    queryFn={milestoneService.milestoneList}
                    queryKey={['milestoneList', taskData?.project_id]}
                    isCustomFunction
                    initialValue={milestoneData}
                    getFunctionParams={(searchKey) => {
                      return {
                        searchKey: searchKey,
                        select: ['id', 'name', 'progress_status'],
                        project_id: taskData?.project_id,
                      };
                    }}
                    onChange={(val) => handleTaskUpdate({ milestone_id: val?.id })}
                    placeholder={
                      'Select ' +
                      (taskData?.project_type === projectConstants.MILESTONE
                        ? 'Milestone'
                        : 'Sprint')
                    }
                    styles={customFlagSelectBoxStyles}
                    className='w-100'
                    value={milestoneData?.filter(
                      (item) => item.id == parseInt(taskData?.milestone_id),
                    )}
                  />
                </div>
              </div>
            )}
          {/* milestone selection end */}

          {/* contact section  */}
          {(taskData?.edit_fields?.includes(LABEL_OF_CLIENT) || taskData?.client_id != null) &&
            hasExist(MENU.contact.default_name) && (
              <div className='task-single-view-item-container position-relative'>
                <div className='task-single-menu-item gap-1 align-items-center'>
                  <div className='task-single-menu-icon'>
                    <ProjectIcon />
                  </div>
                  {LABEL_OF_CLIENT}
                </div>
                <div
                  className={`task-single-menu-value  ${
                    taskData?.edit_fields?.includes(LABEL_OF_CLIENT) ? 'underline-hover' : ''
                  } `}
                  role={taskData?.edit_fields?.includes(LABEL_OF_CLIENT) && 'button'}
                  onClick={() => taskData?.edit_fields?.includes(LABEL_OF_CLIENT)}
                >
                  {/* {taskData?.client_name ?? 'Select '} */}
                  <div className='task-single-menu-value underline-hover'>
                    <SearchableQuerySelect
                      name='client_id'
                      getOptionLabel={(option) => {
                        return option?.name;
                      }}
                      isLoading={clientLoading}
                      getOptionValue={(option) => {
                        return option?.id;
                      }}
                      queryFn={clientService.clientList}
                      queryKey={['client-list-task', taskData?.client_id]}
                      enableCondition={projectBasedClientList?.length === 0}
                      isCustomFunction
                      initialValue={
                        projectBasedClientList?.length === 0 ? clientList : projectBasedClientList
                      }
                      getFunctionParams={(searchKey) => {
                        return {
                          searchKey: searchKey,
                          select: ['id', 'name'],
                        };
                      }}
                      onChange={(val) => handleTaskUpdate({ client_id: val?.id })}
                      placeholder={`Select ${LABEL_OF_CLIENT}`}
                      styles={customFlagSelectBoxStyles}
                      className='w-100'
                      // value={clientList?.filter(
                      //   (item) => item.id == parseInt(taskData?.client_id),
                      // )}
                      value={
                        taskData?.client_id
                          ? { name: taskData?.client_name, id: taskData?.client_id }
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          {/*end of  contact section  */}

          <div className=' task-single-view-item-container '>
            <div className='task-single-menu-item gap-1 align-items-center'>
              <div className='task-single-menu-icon'>
                <Disc2 color='#879096' size={17} />
              </div>
              Status
            </div>
            <div className='task-single-menu-value '>{taskData?.status_name}</div>
          </div>
          <div className='task-single-view-item-container'>
            <div className='task-single-menu-item gap-1 align-items-center'>
              <div className='task-single-menu-icon'>
                <OwnerIcon />
              </div>
              Owner
            </div>
            <div
              className={`task-single-menu-value gap-1 align-items-center position-relative ${
                taskData?.edit_fields?.includes('Allocated To') ? 'underline-hover' : ''
              }  `}
              role={taskData?.edit_fields?.includes('Allocated To') && 'button'}
            >
              <SearchableQuerySelect
                name='assignee_id'
                getOptionLabel={(option) => {
                  return option?.label;
                }}
                isLoading={isUserLoading}
                getOptionValue={(option) => {
                  return option?.value;
                }}
                formatOptionLabel={(data, metaData) => {
                  if (metaData.context == 'value') {
                    const imageUrl = apiConstants.imgUrlS3 + data.image_url;
                    return (
                      <div className='d-flex align-items-center' style={{ gap: 2 }}>
                        <CircularAvatar
                          className={`member-avatar `}
                          isImgStacked
                          size={19}
                          source={imageUrl}
                          isMale={data.isMale}
                        />
                        <div className='task-single-menu-value'>{data.label}</div>
                      </div>
                    );
                  }
                  return <UserDropDown size={19} gap={4} data={data} metaData={metaData} />;
                }}
                queryFn={userService.getPermittedUser}
                queryKey={['userData', taskData?.id]}
                isCustomFunction
                initialValue={userList}
                getFunctionParams={(searchKey) => {
                  return {
                    searchKey: searchKey,
                    action: USER_LIST_ACTIONS.TASK_ADD,
                    select: taskAssignUserSelect,
                    ...(taskData?.project_id ? { project_id: taskData?.project_id } : {}),
                    ...(taskData?.template_id && taskData?.template_id > 0
                      ? { template_id: taskData?.template_id }
                      : {}),
                  };
                }}
                onChange={(value) => handleTaskUpdate({ assignee_id: value?.id })}
                styles={customUserSelectBoxStyles}
                isDisabled={!taskData?.edit_fields?.includes('Allocated To')}
                className='w-100 '
                value={userList?.find((item) => item.value == taskData?.assignee_details?.[0]?.id)}
              />
            </div>
          </div>
          <div className='col-md-6 gap-1 d-flex py-2 h-40px align-items-center'>
            <div className='task-single-menu-item gap-1 align-items-center'>
              <div className='task-single-menu-icon'>
                <PriorityIcon />
              </div>
              Priority
            </div>
            <EditableField
              onSave={(val) => handleTaskUpdate({ priority: val?.priority?.id })}
              key={1}
              type='select'
              placeholder='Select Priority'
              list={options}
              isEditable={taskData?.edit_fields?.includes('Priority')}
              data={taskData?.priority}
              name='priority'
            >
              <div
                className='task-single-menu-value priority-box'
                role={taskData?.edit_fields?.includes('Priority') && 'button'}
              >
                <text className='priority-text'>
                  {taskData?.priority && options?.length > 0
                    ? options?.find((item) => item.value == taskData?.priority)?.label
                    : taskData?.edit_fields?.includes('Priority')
                    ? 'Select Priority'
                    : ' -- '}
                </text>
              </div>
            </EditableField>
          </div>
          {((taskData?.visible_fields?.includes('Label') && taskData?.label_details) ||
            taskData?.edit_fields?.includes('Label')) && (
            <div className='task-single-view-item-container align-items-center'>
              <div className='task-single-menu-item gap-1 align-items-center'>
                <div className='task-single-menu-icon'>
                  <TagsIcon />
                </div>
                Label
              </div>
              <div
                className=' position-relative'
                role={taskData?.edit_fields?.includes('Label') && 'button'}
                onClick={() => taskData?.edit_fields?.includes('Label') && setContextLabel(true)}
              >
                {taskData?.label_details ? (
                  <HorizontalScrollMenu items={taskData?.label_details} />
                ) : (
                  <span className='task-single-menu-item cursor-pointer underline-hover'>
                    Select...{' '}
                  </span>
                )}
                {contextLabel && (
                  <SelectableContextMenu
                    isContextVisible={true}
                    onRequestClose={() => setContextLabel(false)}
                  >
                    <CustomLabelSelectModal
                      defaultLabelDetails={taskData?.label_details}
                      labelData={labelData}
                      key={1}
                      labelDataLoading={isLabelLoading}
                      setLabelSearch={(value) => setLabelSearch(value)}
                      handleAdd={(item) => handleLabelUpdate(item)}
                      handleRemove={(item) => handleLabelRemove(item)}
                    />
                  </SelectableContextMenu>
                )}
              </div>
            </div>
          )}

          <div className='task-single-view-item-container'>
            <div className='task-single-menu-item gap-2 align-items-center'>
              <div className='task-single-menu-icon'>
                <Flag color='#879096' size={18} />
              </div>
              Flag
            </div>
            <div className='task-single-menu-value cursor-pointer underline-hover'>
              <ReactSelect
                value={orderSequence.find((option) => option.value == taskData?.order_seq)}
                onChange={(selectedOption) => {
                  handleTaskUpdate({ order_seq: selectedOption.value });
                }}
                styles={customFlagSelectBoxStyles}
                className='w-100'
                options={orderSequence}
              />
            </div>
          </div>
          <div className='task-single-view-item-container'>
            <div className='task-single-menu-item gap-1 align-items-center'>
              <div className='task-single-menu-icon'>
                <Calendar color='#879096' size={18} />
              </div>
              Created on
            </div>
            <div className='task-single-menu-value'>
              {moment(parseInt(taskData?.created_time)).format('DD MMM YYYY')}
            </div>
          </div>
        </div>

        <div className='col-md-6'>
          <div className='d-flex gap-1 py-2 h-40px align-items-center'>
            <div className='task-single-menu-item gap-1 align-items-center'>
              <div className='task-single-menu-icon'>
                <Hourglass color='#879096' size={18} />
              </div>
              Timeline
            </div>
            <div className={`${taskData?.edit_fields?.includes('Date') ? 'underline-hover' : ''}`}>
              <DatePicker
                calenderStyle={{
                  position: 'absolute',
                  zIndex: 1000,
                  background: 'white',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  borderRadius: '8px',
                  right: 0,
                }}
                onDateChange={(value) => {
                  onSaveDate({
                    task_start_date: moment(value?.startDate).valueOf(),
                    task_end_date: moment(value?.endDate).valueOf(),
                  });
                }}
                initialStartDate={parseInt(taskData?.task_start_date)}
                initialEndDate={parseInt(taskData?.task_end_date)}
                showLabel
                isEditable={taskData?.edit_fields?.includes('Date')}
                inputContainerClass={`editable-time-line ${
                  taskData?.edit_fields?.includes('Date') ? 'cursor-pointer' : ''
                } `}
                monthFormat='short'
              />{' '}
            </div>
          </div>
          <div
            className={`d-flex gap-1 py-2 h-40px align-items-center position-relative ${
              taskData?.task_type == taskType.nonTimeBounded ? 'd-none' : ''
            }`}
          >
            <div className='task-single-menu-item gap-1 align-items-center'>
              <div className='task-single-menu-icon'>
                <Clock3 color='#879096' size={18} />
              </div>
              Expected Time
            </div>
            <div
              className={`task-single-menu-value ${
                taskData?.edit_fields?.includes('Time') ? 'underline-hover' : ''
              } `}
              role={taskData?.edit_fields?.includes('Time') && 'button'}
              onClick={() => taskData?.edit_fields?.includes('Time') && setContextTime(true)}
            >
              {getTaskTime(taskData?.expected_hours)}
            </div>
            {contextTime && (
              <RequestHourInput
                onSubmit={(val) => {
                  onSaveTime(val);
                }}
                setIsTimeInputVisible={setContextTime}
                initialValue={{
                  expected_hours: Math.floor(taskData?.expected_hours),
                  expected_minutes: Math.round((taskData?.expected_hours % 1) * 60),
                }}
                isHeadInvisible
              />
            )}
          </div>
          <div
            className={`task-single-view-item-container ${
              taskData?.task_type == taskType.nonTimeBounded ? 'd-none' : ''
            }`}
          >
            <div className='task-single-menu-item gap-1 align-items-center'>
              <div className='task-single-menu-icon'>
                <EffortsIcon />
              </div>
              Sum of Efforts
            </div>
            <div className='task-single-menu-value '>
              {/* {getTimeTaken(parseInt(taskData?.sum_of_efforts))} */}
              {isExceeded && (
                <span className='is-exceeded'>
                  <ArrowUp color='#DD476A' />
                </span>
              )}
              {formatMillisecondFeatured(parseInt(taskData?.sum_of_efforts), true)}
            </div>
          </div>
          {hasExist(MENU.okr?.default_name) && (
            <div
              className={`task-single-view-item-container
                ${
                  taskData?.okr_details?.length > 0 || taskData?.edit_fields.includes('Parent Okr')
                    ? ''
                    : 'd-none'
                }
                `}
              // className={`task-single-view-item-container ${
              //   taskData?.okr_details?.length > 0 ? '' : 'd-none'
              // }`}
            >
              <div className='task-single-menu-item gap-1 align-items-center'>
                <div className='task-single-menu-icon'>
                  <OkrIcon height={18} width={16} color='#87909E' iconForSideBar='#87909E' />
                </div>
                Okr
              </div>

              <div
                ref={okrListRef}
                onClick={() => {
                  taskData?.edit_fields?.includes('Parent Okr') && setOkrListPopup(true);
                }}
                style={{ fontWeight: 500, position: 'relative' }}
                className={`task-single-menu-value ${
                  taskData?.edit_fields?.includes('Parent Okr') ? 'underline-hover' : ''
                } `}
                role={taskData?.edit_fields?.includes('Parent Okr') && 'button'}
              >
                {/* <ToolTip tooltipText={'Assign okr'} isModern popOver hideTooltip={okrListPopup}> */}
                {taskData?.okr_details?.[0]?.name ? (
                  <span className='single-okr-strip'> {taskData?.okr_details?.[0]?.name}</span>
                ) : (
                  <span className='d-flex justify-content-center align-items-center gap-1 '>
                    <Plus size={17} />
                    <span>Add Okr</span>
                  </span>
                )}
                {/* </ToolTip> */}
                {okrListPopup && (
                  <UserListSelectionPopup
                    userList={okrData?.data?.rows}
                    setSearchKey={setOkrSearch}
                    searchKey={okrSearch}
                    handleSave={handleOkrSelectionSubmit}
                    // selectedUsers={project?.allocated_users}
                    handleClose={(event) => {
                      setOkrSearch('');
                      event.stopPropagation();
                      setOkrListPopup(false);
                    }}
                    isLoading={okrLoading}
                    isUserList={false}
                  />
                )}
              </div>
            </div>
          )}
          {taskData?.has_time && taskData?.start_time && (
            <div className={`d-flex gap-1 py-2 h-40px align-items-center position-relative`}>
              <div className='task-single-menu-item gap-1 align-items-center'>
                <div className='task-single-menu-icon'>
                  <Clock3 color='#879096' size={18} />
                </div>
                Start Time
              </div>
              <div
                className={`task-single-menu-value underline-hover pointer `}
                // role={taskData?.edit_fields?.includes('Time') && 'button'}
                onClick={() => setStartTimeAddVisible(true)}
              >
                {taskData?.start_time?.hours +
                  ':' +
                  (taskData?.start_time?.minutes < 10
                    ? '0' + taskData?.start_time?.minutes
                    : taskData?.start_time?.minutes) +
                  ' ' +
                  taskData?.start_time?.period}
                {startTimeAddVisible && (
                  <CustomStartTimeInput
                    setTimeAddVisible={setStartTimeAddVisible}
                    timeAddVisible={startTimeAddVisible}
                    onSave={(data) => {
                      handleTaskUpdate(data);
                      setStartTimeAddVisible(false);
                    }}
                    time={{ ...taskData?.start_time }}
                    isHeadInVisible={true}
                  />
                )}
              </div>
            </div>
          )}
          {taskData?.has_time && taskData?.end_time && (
            <div className={`d-flex gap-1 py-2 h-40px align-items-center position-relative`}>
              <div className='task-single-menu-item gap-1 align-items-center'>
                <div className='task-single-menu-icon'>
                  <Clock3 color='#879096' size={18} />
                </div>
                End Time
              </div>
              <div
                className={`task-single-menu-value underline-hover pointer `}
                onClick={() => setEndTimeAddVisible(true)}
              >
                {taskData?.end_time?.hours +
                  ':' +
                  (taskData?.end_time?.minutes < 10
                    ? '0' + taskData?.end_time?.minutes
                    : taskData?.end_time?.minutes) +
                  ' ' +
                  taskData?.end_time?.period}
                {endTimeAddVisible && (
                  <CustomEndTimeInput
                    setTimeAddVisible={setEndTimeAddVisible}
                    timeAddVisible={endTimeAddVisible}
                    onSave={(data) => {
                      handleTaskUpdate(data);
                      setEndTimeAddVisible(false);
                    }}
                    time={{ ...taskData?.end_time }}
                    isHeadInVisible={true}
                  />
                )}
              </div>
            </div>
          )}
          {taskData?.verified_by > 0 && (
            <div className='task-single-view-item-container'>
              <div className='task-single-menu-item gap-1 align-items-center'>
                <div className='task-single-menu-icon'>
                  <UserRoundCheck color='#879096' size={18} />
                </div>
                Verified User
              </div>
              <div className='task-single-menu-value'>
                {taskData?.verified_user?.[0]?.first_name +
                  ' ' +
                  taskData?.verified_user?.[0]?.last_name}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* {isProjectModal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => setIsProjectModal(false)} Heading={''} />
            <div className='d-flex'>
              <SearchableQuerySelect
                name='milestone_id'
                getOptionLabel={(option) => {
                  return option?.name;
                }}
                isLoading={milestoneLoading}
                getOptionValue={(option) => {
                  return option?.id;
                }}
                queryFn={milestoneService.milestoneList}
                queryKey={['milestoneList', taskData?.project_id]}
                isCustomFunction
                initialValue={milestoneData}
                getFunctionParams={(searchKey) => {
                  return {
                    searchKey: searchKey,
                    select: ['id', 'name', 'progress_status'],
                    project_id: taskData?.project_id,
                  };
                }}
                onChange={(val) => handleTaskUpdate({ milestone_id: val?.id })}
                placeholder={
                  'Select ' +
                  (taskData?.project_type === projectConstants.MILESTONE ? 'Milestone' : 'Sprint')
                }
                className='w-100'
                value={milestoneData?.filter((item) => item.id == parseInt(taskData?.milestone_id))}
              />
            </div>
            <div className='d-flex'></div>
          </Modal.View>
        </Modal.Container>
      )} */}
    </div>
  );
}

export default TaskEditableSingleView;
