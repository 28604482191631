import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ArrowLeft, Edit, Home, Phone } from 'lucide-react';
import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import IconLabelSet from '../_components/IconLabelSet';
import ToolTip from '../_components/Tooltip/ToolTip';
import { apiConstants, MENU, RT } from '../_constants';
import { clientService } from '../_services';
import { ProjectDetailsBox } from '../Project';
import { fullNameUser, GlobalContext, MyTeamIcon } from '../Utils';
import { AtIcon, ClientIcon, CompanyIcon, FileIcon, LoadingIcon } from '../Utils/SvgIcons';
import { ClientAdd } from './ClientAdd';
import ClientLayout from './ClientLayout';

export const ClientDetails = () => {
  const { getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  const id = useParams()?.id;
  const navigate = useNavigate();
  const [editModal, setEditModal] = useState(false);

  const {
    data: clientData,
    // error,
    isLoading: isClientLoading,
    refetch,
  } = useQuery({
    queryKey: ['contact-single-view', id],
    queryFn: () =>
      clientService.clientGet({
        select: ['id', 'name', 'company_name', 'email', 'phone'],
        id: id,
      }),
    select: (data) => data?.data?.[0],
    placeholderData: keepPreviousData,
    enabled: Boolean(id),
  });
  const ownerName = fullNameUser(
    clientData?.creator_details?.first_name,
    clientData?.creator_details?.last_name,
  );
  const isMale = clientData?.creator_details?.gender === 1;
  const isNeutral = clientData?.creator_details?.gender === 3;
  const url = clientData?.clientData?.creator_details?.image_url;
  const finalUrl = url ? apiConstants.imgUrlS3 + url : null;

  if (isClientLoading) {
    return (
      <div className='template-add-container page-height d-flex justify-content-center align-items-center'>
        <LoadingIcon size={60} />
      </div>
    );
  }

  return (
    <div>
      <div className=' url-container '>
        <div className='d-flex align-items-center breadcrumbs'>
          <div className='me-2 px-1' style={{ borderRight: '1px solid #F0F0F0' }}>
            <ArrowLeft
              color='#87909E'
              height={23}
              width={23}
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={13} />}
            onClick={() => navigate(`/`)}
          />
          {/* {isAdmin && ( */}

          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <IconLabelSet
            label={LABEL_OF_CLIENT}
            fontWeight={500}
            icon={<ClientIcon color='#2A2E34' height={16} width={15} />}
            onClick={() => {
              navigate(`/${RT.CLIENT}`);
            }}
          />
          {/* )} */}
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <div className='font-13 text-nowrap'>{clientData?.name}</div>
        </div>
      </div>

      <div
        className='project-table-container '
        style={{ maxWidth: '80vw', padding: 0, position: 'relative' }}
      >
        <div
          className='project-pin-icon pointer client-edit-icon'
          onClick={() => setEditModal(true)}
        >
          <ToolTip isModern tooltipText={`Edit ${LABEL_OF_CLIENT} `}>
            <Edit size={18} />
          </ToolTip>
        </div>

        {/* project details  */}
        <div className='project-single-header-container'>
          {/* project name   */}
          <div className='project-heading'>{clientData?.name}</div>
          <div className='project-details'>
            <ProjectDetailsBox
              icon={
                <CircularAvatar
                  size={30}
                  source={finalUrl}
                  className='fz-none zoom-in employee-img-with-low-border ms-0 me-0'
                  isMale={isMale}
                  isNeutral={isNeutral}
                />
              }
              heading={'Owner'}
              value={ownerName}
            />
            {clientData?.company_name && (
              <ProjectDetailsBox
                icon={
                  <div className='first_Letter_bg'>
                    <CompanyIcon />
                  </div>
                }
                heading={'Company'}
                value={clientData?.company_name}
              />
            )}
            {clientData?.phone && (
              <ProjectDetailsBox
                icon={
                  <div className='first_Letter_bg'>
                    <Phone size={15} color='#2A2E34' />
                  </div>
                }
                heading={'Phone'}
                value={clientData?.phone}
              />
            )}
            {clientData?.email && (
              <ProjectDetailsBox
                icon={
                  <div className='first_Letter_bg'>
                    <AtIcon color='#2a2e34' />
                  </div>
                }
                heading={'Email'}
                value={clientData?.email}
              />
            )}
            <ProjectDetailsBox
              icon={
                <div className='first_Letter_bg'>
                  <FileIcon />
                </div>
              }
              heading={'Task Status'}
              value={`
                ${clientData?.completed_task_count} of ${clientData?.total_task_count} completed`}
            />
          </div>
        </div>
        <div className='project-separation'></div>
        <ClientLayout clientData={clientData} />
      </div>
      {editModal && (
        <Modal.Container handleClose={() => setEditModal(false)}>
          <Modal.View className={'modal_view_label'}>
            <Modal.Head
              Heading={'Edit Contact'}
              handleClose={() => {
                setEditModal(false);
              }}
            />
            <ClientAdd
              id={clientData?.id}
              handleClose={() => {
                setEditModal(false);
                refetch();
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
};
