/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Demo, DemoAdd, DemoList } from '../Demo';
import Home from '../Home/Home';
import Profile from '../Profile/Profile';
import { UserList } from '../User';
import { GlobalContext } from '../Utils/Contexts';
import { Init, RootLayout } from '../_components';
import { RT } from '../_constants';
import AlertComponent from './AlertComponent';

import { Chat, ChatAdd, ChatList } from '../Chat';
import { DepartmentList, DesignationList } from '../Department';
import { LabelList } from '../Label';
import { MyTeam, MyTeamAdd, MyTeamList } from '../Myteam';
import { Organization, OrganizationAdd, OrganizationList } from '../Organization';
import { Project, ProjectAdd, ProjectList } from '../Project';
import { GraphicalReport, TimeHistory, WorkActivity, WorkHistory } from '../Report';
import { Request } from '../Request';
import {
  ChatSettings,
  EmployeeSettings,
  GeneralSettings,
  MenuSettings,
  NotificationSettings,
  PermissionSettings,
  ProfileSettings,
  ProjectSettings,
  SettingsLayout,
  TaskSettings,
  ThemeSettings,
  TimeSettings,
} from '../Settings';
import { Task, TaskAdd, TaskList } from '../Task';
import { Template, TemplateList } from '../Template';
import { Todo, TodoAdd, TodoList } from '../Todo';

import { useIsFetching, useQuery } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ForgotPassword from '../Auth/ForgotPassword/ForgotPassword';
import Login from '../Auth/Login';
import BranchList from '../Branch/BranchList';
import { ClientList } from '../Client';
// import { FileList } from '../File/FileList';
import FileList from '../FileSystem/FileList';

import { AITaskManager } from '../AI/AITaskManager';
import ChatInterface from '../AI/AiChat';
import GenerateDescription from '../AI/GenerateDescription';
import Speaker from '../AI/Speaker';
import { ArchiveList } from '../Archive/ArchiveList';
import { NoteEditor } from '../BoardView/NoteEditor';
import { One } from '../BoardView/One';
import TimeBoxCalendar from '../Calander/TimeBoxCalendar';
import { ClientDetails } from '../Client/ClientDetails';
import { ClientTaskList } from '../Client/ClientTaskList';
import FileHome from '../FileSystem/FileHome';
import FolderList from '../FileSystem/FolderList';
import { Game } from '../Game/Game';
import useInternetStatus from '../Hook/useInternetStatus';
import { usePermissions } from '../Hook/usePermissions';
import { useUser } from '../Hook/useUser';
import MilestoneList from '../Milestone/MilestoneList';
import ModifiedNotificationFullView from '../Notification/ModifiedNotificationFullView';
import { OKRList } from '../OKR';
import { OkrSingleView } from '../OKR/OkrSingleView';
import ProjectBoard from '../Project/ProjectBoard';
import ProjectCalendar from '../Project/ProjectCalendar';
import ProjectTaskList from '../Project/ProjectTaskList';
import ReminderList from '../Reminders/ReminderList';
import { BarChartReport } from '../Report/BarChartReport';
import { PieChartReport } from '../Report/PieChartReport';
import { TaskReport } from '../Report/TaskReport';
import { TeamReport } from '../Report/TeamReport';
import TimeSheetList from '../Report/TimeSheet/TimeSheetList';
import AccessDenied from '../Settings/AccessDenied';
import OrganizationLayout from '../Settings/OrganizationLayout';
import RecurringTest from '../Task/RecurringTask/RecurringTest';
import TeamList from '../Team/TeamList';
import TemplateAddModal from '../Template/TemplateAddModal';
import ReloadCheck from '../Utils/Reload';
import { Test } from '../Utils/Test';
import Ui from '../Utils/Ui';
import ActionPopup from '../_components/ActionPopup/ActionPopup';
import { ReminderView } from '../_components/Reminder';
import { settingsService, taskService } from '../_services';
import { permissionService } from '../_services/permission.service';

export default function Router() {
  const isOnline = useInternetStatus({ offLineNavigate: true, offlinePage: `/${RT.GAME}` });

  const [alert, setAlert] = useState({ type: '', message: '' });
  // Assuming you are using a modal that triggers this script
  // Assuming you are using a modal that triggers this script
  document.addEventListener('DOMContentLoaded', function () {
    const modal = document.getElementById('MODAL');

    modal.addEventListener('show.bs.modal', function () {
      // When the modal is shown, add the 'modal-open' class to the body
      document.body.classList.add('modal-open');
    });

    modal.addEventListener('hidden.bs.modal', function () {
      // When the modal is hidden, remove the 'modal-open' class from the body
      document.body.classList.remove('modal-open');
    });
  });

  // Don't want to show progress for notification and last-message in chats, which calls based on interval.
  const isFetching = useIsFetching({
    predicate: (query) =>
      !query.queryKey.some((qyKey) => ['notificationCount', 'latest-messages'].includes(qyKey)),
  });
  const [actionAlert, setActionAlert] = useState({ type: '', message: null });
  const [globalSettings, setGlobalSettings] = useState({});

  const user = useUser();
  let timeout;

  const getAllGlobalSettings = async () => {
    let settingsData = await settingsService.getGlobalSettings();
    setGlobalSettings(settingsData.data);
    return settingsData;
  };

  // get active task
  const { data: permissions, refetch: getPermissions } = useQuery({
    queryKey: ['permissions'],
    queryFn: () => permissionService.getAllPermissions({}),
    select: (data) => data.data,
    enabled: user?.id ? true : false,
  });

  // get active task
  const { data: activeTask, refetch: currentTaskRefetch } = useQuery({
    queryKey: ['CurrentTask'],
    queryFn: () => taskService.getActiveTask({}),
    select: (data) => data.data,
    enabled: user?.id ? true : false,
  });

  const { refetch: globalSettingsRefetch, isLoading: isSettingLoading } = useQuery({
    queryKey: ['settings'],
    queryFn: () => getAllGlobalSettings(),
    select: (data) => {
      return data?.data;
    },
    enabled: user?.id ? true : false,
  });

  const makeAlert = (message, type, time) => {
    setAlert((prev) => ({
      ...prev,
      message: message,
      type: type || 'success',
    }));
    clearTimeout(timeout);
    timeout = setTimeout(
      () => {
        setAlert((prev) => ({
          ...prev,
          message: null,
          type: type || 'success',
        }));
      },
      time ? time * 1000 : 3000,
    );
  };

  const permissionsMap = usePermissions(permissions);

  const menuSettings =
    globalSettings &&
    globalSettings?.length &&
    globalSettings?.find((settings) => settings?.name === 'menu-settings');
  function getMenuLabelName(defaultName) {
    const label =
      menuSettings && menuSettings?.labels?.find((label) => label?.default_name === defaultName);
    return label ? label.name : null;
  }

  return (
    <>
      <GlobalContext.Provider
        value={{
          alert,
          makeAlert,
          setActionAlert,
          globalSettings,
          globalSettingsRefetch,
          activeTask,
          currentTaskRefetch,
          isSettingLoading,
          permissions,
          getPermissions,
          permissionsMap,
          getMenuLabelName,
        }}
      >
        <AlertComponent />
        <ReminderView />

        {/* Will show a global loader, if api calls and takes more than 500ms to complete. */}
        {Boolean(isFetching) && (
          <div className='indeterminate-progress-container'>
            <div className='indeterminate-progress-bar'></div>
          </div>
        )}

        <ActionPopup actionAlert={actionAlert} />

        {/* DON'T REMOVE: NEEDED FOR TESTING SSE */}
        {/* <TestSse /> */}
        <div id='MODAL'></div>
        <Routes>
          <Route path='/' element={<RootLayout />}>
            <Route index element={<Home />} />
            <Route path={`${RT.PROFILE}/:id`} element={<Profile />} />
            {/* employee */}
            {/* <Route path={`employee-add/:id`} element={<EmployeeAdd />} /> */}

            {/* demo */}
            <Route path={`${RT.DEMO}/:id`} element={<Demo />} />
            <Route path={RT.DEMO_LIST} element={<DemoList />} />
            <Route path={`${RT.DEMO_ADD}/:id`} element={<DemoAdd />} />
            {/* end demo */}

            {/* Reminder */}

            <Route path={`/${RT.REMINDER_LIST}`} element={<ReminderList />} />

            {/* end Reminder */}

            {/* user */}
            <Route path={RT.USER_LIST} element={<UserList />} />
            <Route path={`${RT.USER}/:id`} element={<Demo />} />
            <Route path={`${RT.USER_ADD}/:id`} element={<DemoAdd />} />
            {/* end user */}

            {/* task */}
            <Route path={`${RT.TASK_LIST}/*`} element={<TaskList />} />
            <Route path={`${RT.TASK}/:id`} element={<Task />} />
            <Route path={`${RT.TASK_ADD}/:id`} element={<TaskAdd />} />

            {/* end task */}

            {/* task */}
            <Route path={RT.TEMPLATE_LIST} element={<TemplateList />} />
            <Route path={`${RT.TEMPLATE}/:id`} element={<Template />} />
            <Route path={`${RT.TEMPLATE_ADD}/:id`} element={<TemplateAddModal />} />

            {/* end task */}

            {/* todo */}
            <Route path={RT.TODO_LIST} element={<TodoList />} />
            <Route path={`${RT.TODO}/:id`} element={<Todo />} />
            <Route path={`${RT.TODO_ADD}/:id`} element={<TodoAdd />} />
            {/* end todo */}

            {/* my-team */}
            {/* <Route path={RT.MY_TEAM_LIST_MAIN} element={<MyTeamListMain />}>
              <Route path={RT.MY_TEAM_LIST} element={<MyTeamList />} />
              <Route path={RT.MY_TEAM_CALENDAR} element={<CalendarViewMyTeam />} />
            </Route> */}
            <Route path={RT.MY_TEAM_LIST} element={<MyTeamList />} />
            <Route path={`${RT.MY_TEAM}/:id`} element={<MyTeam />} />
            <Route path={`${RT.MY_TEAM_ADD}/:id`} element={<MyTeamAdd />} />
            {/* end myteam */}

            {/* organization */}
            <Route path={RT.ORGANIZATION_LIST} element={<OrganizationList />} />
            <Route path={`${RT.ORGANIZATION}/:id`} element={<Organization />} />
            <Route path={`${RT.ORGANIZATION_ADD}/:id`} element={<OrganizationAdd />} />
            {/* end organization */}

            {/* OKR */}
            <Route path={RT.OKR_LIST} element={<OKRList />} />
            <Route path={`${RT.OKR_VIEW}/:id`} element={<OkrSingleView />} />
            {/* end OKR */}

            {/* project */}
            <Route path={RT.PROJECT_LIST} element={<ProjectList />} />
            <Route path={`${RT.PROJECT_ADD}/:id`} element={<ProjectAdd />} />
            <Route path={`${RT.PROJECT}/:id`} element={<Project />}>
              <Route path={RT.MILESTONE} element={<MilestoneList />} />
              <Route path={RT.PROJECT_TASK_LIST} element={<ProjectTaskList />} />
              <Route path={RT.PROJECT_BOARD} element={<ProjectBoard />} />
              <Route path={RT.PROJECT_CALENDAR} element={<ProjectCalendar />} />
            </Route>
            {/* end project */}

            {/* contact */}
            <Route path={RT.CLIENT} element={<ClientList />} />
            <Route path={`${RT.CLIENT_SINGLE_PAGE}/:id`} element={<ClientDetails />}>
              <Route path={RT.CLIENT_TASK_LIST} element={<ClientTaskList />} />
              <Route path={RT.FILELIST} element={<FileList />} />
            </Route>
            {/* end contact */}

            {/* chat */}
            <Route path={RT.CHAT_LIST} element={<ChatList />} />
            <Route path={RT.CHAT} element={<Chat />} />
            <Route path={`${RT.CHAT_ADD}/:id`} element={<ChatAdd />} />

            {/* <Route path='teamstacked' element={<TestTeam />} /> */}

            {/* end chat */}

            {/* calender */}
            <Route
              path={RT.CALENDAR}
              element={
                <div className='p-4'>
                  <TimeBoxCalendar />
                </div>
              }
            />
            {/* end calender */}

            {/* request */}
            <Route path={RT.REQUEST} element={<Request />} />
            {/* end request */}

            {/* notification */}

            <Route path={`${RT.NOTIFICATION}/:id`} element={<ModifiedNotificationFullView />} />
            {/* notification end */}

            {/* settings */}
            <Route path={RT.SETTINGS} element={<SettingsLayout />}>
              <Route path={RT.GENERAL} element={<GeneralSettings />} />
              <Route path={RT.MENU} element={<MenuSettings />} />
              <Route path={RT.PROJECT_SETTINGS} element={<ProjectSettings />} />
              <Route path={RT.TASK_SETTINGS} element={<TaskSettings />} />
              <Route path={RT.EMPLOYEE_SETTINGS} element={<EmployeeSettings />} />
              <Route path={RT.PROFILE_SETTINGS} element={<ProfileSettings />} />
              <Route path={RT.CHAT_SETTINGS} element={<ChatSettings />} />
              <Route path={RT.NOTIFICATION_SETTINGS} element={<NotificationSettings />} />
              <Route path={RT.TIME_SETTINGS} element={<TimeSettings />} />
              <Route path={RT.WORK_SPACE} element={<OrganizationLayout />}>
                <Route path={RT.ORGANIZATION} element={<OrganizationList />} />
                <Route path={RT.DEPARTMENT} element={<DepartmentList />} />
                <Route path={RT.DESIGNATION} element={<DesignationList />} />
                <Route path={RT.BRANCH} element={<BranchList />} />
              </Route>

              <Route path={RT.TEAM} element={<TeamList />} />
              <Route path={RT.LABEL} element={<LabelList />} />
              <Route path={RT.PERMISSION} element={<PermissionSettings />} />
              <Route path={RT.THEME} element={<ThemeSettings />} />
              <Route path={RT.CLIENT} element={<ClientList />} />
            </Route>

            {/* end settings */}
            {/* editor */}
            <Route path={RT.EDITOR} element={<NoteEditor />} />
            {/* editor */}

            {/* AI   */}
            <Route path={RT.AI} element={<AITaskManager />} />
            {/* end AI */}

            {/* report */}
            <Route path={RT.TIME_HISTORY} element={<TimeHistory />} />
            <Route path={RT.TIME_SHEET} element={<TimeSheetList />} />
            <Route path={RT.TEAM_REPORT} element={<TeamReport />} />

            <Route path={RT.GRAPH_REPORT} element={<GraphicalReport />}>
              <Route path={RT.PIE_CHART} element={<PieChartReport />} />
              <Route path={RT.BAR_CHART} element={<BarChartReport />} />
            </Route>
            <Route path={RT.TASK_REPORT} element={<TaskReport />} />
            {/* <Route path={RT.WORK_HISTORY} element={<WorkHistoryReport />} /> */}
            <Route path={RT.WORK_HISTORY} element={<WorkHistory />} />
            <Route path={RT.WORK_ACTIVITY} element={<WorkActivity />} />
            {/* <Route path={RT.BOARD_VIEW} element={<BoardContainer />} /> */}
            <Route path={'one'} element={<One />} />

            {/* end request */}

            {/* file */}
            {/* <Route path={RT.FILE} element={<File />}>
              <Route path={RT.DIGITAL_DOCUMENT} element={<DigitalDocument />} />
              <Route path={RT.PHYSICAL_DOCUMENT} element={<PhysicalDocument />} />
              <Route path={RT.ALL_FOLDER} element={<AllFolder />} />
            </Route> */}
            {/* end file */}

            {/* file system  */}

            <Route path={RT.FILESYSTEM} element={<FileHome />}>
              <Route path={RT.FILELIST} element={<FileList />} />
              <Route path={RT.FOLDERLIST} element={<FolderList />} />
              <Route path={`${RT.FILELIST}/:folderId`} element={<FileList />} />
            </Route>

            {/* end of file system  */}

            <Route path={RT.ARCHIVE} element={<ArchiveList />} />

            {/* Test routes */}
            <Route path='recur-test' element={<RecurringTest />} />

            <Route path='Description' element={<GenerateDescription />} />
            <Route path='ChatInterface' element={<ChatInterface />} />
            <Route path='Speak' element={<Speaker />} />
            <Route path='Test' element={<ReloadCheck />} />
            <Route path='Init' element={<Init />} />
            <Route path='404' element={<AccessDenied />} />
            <Route path='ui' element={<Ui />} />

            {/* end of test routes */}
          </Route>
          {/* non authenticated */}

          <Route path={RT.LOGIN} element={<Login />} />
          <Route path={RT.GAME} element={<Game />} />
          <Route path={'one'} element={<One />} />
          {/* <Route path={'editor'} element={<NoteEditor />} /> */}
          <Route path='test' element={<Test />} />

          <Route path={RT.FORGOT_PASSWORD} element={<ForgotPassword />} />

          {/*end non authenticated */}
        </Routes>
        <ReactQueryDevtools buttonPosition='bottom-left' initialIsOpen={false} />
      </GlobalContext.Provider>
      {/* <ContactContext.Provider
        value={{
          getMenuLabelName,
        }}
      ></ContactContext.Provider> */}
    </>
  );
}
