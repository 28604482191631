export const asyncStyle = {
  control: (styles) => {
    return {
      ...styles,
      minHeight: '35px',
    };
  },
};

export const projectAddProjectSelect = [
  'id',
  'name',
  'start_date',
  'end_date',
  'logo',
  'note',
  'company_id',
  'estimated_cost',
  'allocated_users',
  'project_cost',
  'status',
  'client_id',
  'project_type',
  'project_owner_id',
  'template_id',
  'allow_multiple_template',
  'is_shared',
  'team_lead',
];
// const LABEL_OF_PROJECT = 'project';
export const PROJECT_ERROR_MSG = {
  NAME: `Invalid  name`,
  // START_DATE: 'Invalid start date ',
  // END_DATE: 'Invalid end date',
  ALLOCATED_USER: 'Invalid allocated user',
  DATE_RANGE: 'End Date must be grater than Start date',
  // CLIENT: 'Invalid client',
  PROJECT_OWNER: `Invalid owner`,
  TEMPLATE: 'Invalid template',
};

export const PROJECT_REQUIRED_FIELD_MSG = {
  NAME: `Name required`,
  // START_DATE: 'Start date  required',
  // END_DATE: 'End date required',
  ALLOCATED_USERS: 'Allocated user required',
  DATE_RANGE: 'End Date must be grater than Start date',
  // CLIENT: `${LABEL_OF_CLIENT}  required`,
  PROJECT_OWNER: `Owner required`,
  TEMPLATE: 'Template required',
};

export const DropDownStyle = {
  container: (styles) => {
    return {
      ...styles,
      fontSize: '13px',
      fontWeight: '400',
    };
  },

  control: (styles, { isFocused }) => {
    return {
      ...styles,
      // height: '34px',
      minHeight: '25px',
      borderColor: isFocused ? '#3454D1' : '#87909E',
      boxShadow: isFocused ? '0 0 0 3px rgba(52, 84, 209, 0.25)' : styles.boxShadow,
      '&:hover': {
        borderColor: isFocused ? '#3454D1' : styles['&:hover']?.borderColor || styles.borderColor,
      },
    };
  },
  indicatorsContainer: (styles) => {
    return {
      ...styles,
      minHeight: '25px',
      height: '33px',
      color: '#87909E',
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      minWidth: '170px',
      fontSize: '13px',
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      maxHeight: '200px',
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      fontWeight: '500',
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => {
    return {
      ...styles,
      color: '#2A2E34',
      '&:hover': {
        color: '#2A2E34',
      },
    };
  },
};
