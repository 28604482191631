/* eslint-disable no-unused-vars */
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { aiService } from '../_services/ai.service';
import './desciption.css'; // Import the CSS file

function GenerateDescription() {
  const [lines, setLines] = useState([]);
  const [taskTitle, setTaskTitle] = useState('');

  const getGenerateDescription = async (taskTitle) => {
    const response = await aiService.generateDescription({ title: taskTitle });
    return response;
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload) => getGenerateDescription(payload),
    onSuccess: (response, payload) => {
      console.log('RSP', response);
      const generatedLines = response.data.split('\n');
      setLines(generatedLines);
    },
  });

  //   const mutation = useMutation(
  //     async (taskTitle) => {
  //       getGenerateDescription(taskTitle);
  //     },
  //     {
  //       onSuccess: (data) => {
  //         const generatedLines = data.description.split('\n');
  //         setLines(generatedLines);
  //       },
  //     },
  //   );

  const handleGenerate = () => {
    const taskTitle = document.getElementById('task-title').value;
    mutateAsync(taskTitle);
  };

  const handleTitleChange = (event) => {
    setTaskTitle(event.target.value);
    setLines([]);
  };

  return (
    <div className='task-form-container'>
      <div className='form-group'>
        <label htmlFor='task-title' className='form-label'>
          Enter Task Title
        </label>
        <input
          type='text'
          id='task-title'
          className='form-input'
          placeholder='Enter your task title'
          value={taskTitle}
          onChange={handleTitleChange}
        />
      </div>

      <button className='ai-button' onClick={handleGenerate}>
        Generate description with AI
      </button>

      <div className='description-output'>
        {isPending ? ( // Conditionally render loading message
          <div className='loading-message'>Generating...</div>
        ) : (
          lines.map((line, index) => (
            <div
              key={index}
              className='animated-line'
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              {line}
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default GenerateDescription;
