import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Calendar, Hash, Shapes } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { components } from 'react-select';
import { Button, TableRow } from '../_components';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import UserDropDown from '../_components/UserDropDown';
import { USER_LIST_ACTIONS } from '../_constants';
import { userService } from '../_services';
import { reportServices } from '../_services/reports.service';
import { useUser } from '../Hook/useUser';
import { Heading } from '../Misc';
import {
  GlobalContext,
  hoursToHoursMinutes,
  hoursToHoursMinutesIn100,
  millisecondsToHoursMinutes,
} from '../Utils';
import { LoadingIcon, User } from '../Utils/SvgIcons';
import { TIME_HISTORY_SELECTION_BOX } from './Report.constant';
import ReportDatePicker from './ReportDatePicker';

export const TimeHistory = () => {
  // const user = useUser(); // Get current user
  // const [userSearch, setUserSearch] = useState('');
  // const debounceUser = useDebounce(userSearch, 500);
  const [searchParams, setSearchParams] = useSearchParams();
  // const [isLoading, setIsLoading] = useState(true); // Loading state
  const [selectedUser, setSelectedUser] = useState(useUser());
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf('month').valueOf(),
    endDate: moment().valueOf(), // Set to current day and time
  });

  const { makeAlert } = useContext(GlobalContext);

  // useEffect(() => {
  //   handleSubmit();
  // }, []);

  // // Initial setup of dates and user ID
  // useEffect(() => {
  //   setSearchParams((prevParams) => {
  //     const updatedParams = new URLSearchParams(prevParams.toString());
  //     updatedParams.set('user_id', user.id);
  //     updatedParams.set('fromDate', dateRange.startDate);
  //     updatedParams.set('toDate', dateRange.endDate);
  //     return updatedParams;
  //   });
  // }, [user]);

  // const workHistoryOfUser = searchParams.get('user_id');
  // const workHistoryFromDate = searchParams.get('fromDate');
  // const workHistoryToDate = searchParams.get('toDate');

  // const [query, setQuery] = useState({
  //   select: ['id', 'start_time', 'end_time', 'time_difference', 'status', 'date'],
  //   pageVo: {
  //     pageNo: parseInt(searchParams.get('pageNo')) || 1,
  //     noOfItems: 10,
  //   },
  // });
  const {
    data: timeHistory,
    isLoading: historyLoading,
    isSuccess,
    isPaused,
  } = useQuery({
    queryKey: ['time-history-list', searchParams.toString()],
    queryFn: () =>
      reportServices.getTimeHistory({
        user_id: selectedUser?.id,
        company_id: 1,
        start_time: dateRange?.startDate,
        end_time: dateRange?.endDate,
      }),
    placeholderData: keepPreviousData,
  });

  const { data: userData, isLoading: isUserLoading } = useQuery({
    queryKey: ['user-list'],
    queryFn: () =>
      userService.getPermittedUser({
        // searchKey: userSearch,
        action: USER_LIST_ACTIONS.WORK_HISTORY,
        select: ['id', 'first_name', 'last_name', 'image_url'],
      }),
    select: (data) => data.data.rows,
    placeholderData: keepPreviousData,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'user_id') {
      setSelectedUser(value);
    }
  };

  const handleDateChange = (dates) => {
    const startDate = new Date(dates.startDate);
    const endDate = new Date(dates.endDate);

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    setDateRange({
      startDate: startDate.getTime(),
      endDate: endDate.getTime(),
    });
  };

  // section trigger when delete option selected
  useEffect(() => {
    if (isPaused) {
      makeAlert('No Internet');
    }
  }, [isPaused]);

  const handleSubmit = () => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams.toString());
      updatedParams.set('user_id', selectedUser.id);
      updatedParams.set('fromDate', dateRange.startDate);
      updatedParams.set('toDate', dateRange.endDate);
      return updatedParams;
    });
  };

  if (isUserLoading) {
    return (
      <div className='page-height d-flex justify-content-center align-items-center'>
        <LoadingIcon size={60} />
      </div>
    );
  }

  const { hoursInTime, minutesInTime } = hoursToHoursMinutes(
    timeHistory?.data?.total_hour?.toFixed(3),
  );

  const { hours, minutes } = hoursToHoursMinutesIn100(timeHistory?.data?.total_hour?.toFixed(3));

  const tableData = timeHistory?.data?.history?.map((workData, index) => {
    // let dateHour = convertHoursAndMinutes(workData?.worked_time);
    const { hours, minutes } = millisecondsToHoursMinutes(workData?.worked_time_milli);

    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{moment.utc(workData?.date).format('ddd, DD MMM YYYY')}</td>
        <td>
          {workData?.worked_time <= 0 ? (
            <span>--</span>
          ) : (
            ` ${hours.toString().padStart(2, '0')}:
              ${minutes.toString().padStart(2, '0')}`
          )}
        </td>
      </tr>
    );
  });

  return (
    <div className='time-history'>
      <Heading title={'Time History'} />
      <div className='mt-4 d-flex gap-3 time-history-filter-container'>
        {/* <div style={{ width: 'fit-content' }}>
          <ReactSelect
            styles={{
              control: (provided, state) => ({
                ...provided,
                background: '#eeeeee',
                minHeight: '10px',
                minWidth: '100px',
                border: 'none',
                fontSize: '13px',
                boxShadow: state.isFocused ? 'none' : provided.boxShadow,
              }),
              menu: (provided) => ({
                ...provided,
                minWidth: '250px',
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
                whiteSpace: 'normal',
                overflow: 'auto',
              }),
              indicatorSeparator: (provided) => ({
                ...provided,
                display: 'none',
              }),
            }}
            placeholder='Member'
            onInputChange={(inputString) => {
              setUserSearch(inputString);
            }}
            inputValue={userSearch}
            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
            formatOptionLabel={(data, metaData) => <UserDropDown data={data} metaData={metaData} />}
            getOptionValue={(option) => option.id}
            onChange={(selectedOption) => {
              handleChange({
                target: {
                  name: 'user_id',
                  value: selectedOption,
                },
              });
            }}
            options={userData}
            value={selectedUser}
          />
        </div> */}

        <div>
          <SearchableQuerySelect
            isClearable
            queryKey={'document-type-list'}
            queryFn={userService.getPermittedUser}
            getFunctionParams={(searchKey) => ({
              searchKey,
              action: USER_LIST_ACTIONS.WORK_HISTORY,
              select: ['id', 'first_name', 'last_name', 'image_url'],
            })}
            isCustomFunction
            initialValue={userData}
            menuPortalTarget={document.getElementById('MODAL')}
            formatOptionLabel={(data, metaData) => (
              <UserDropDown data={data} metaData={metaData} hideDesignation={true} />
            )}
            getOptionValue={(option) => option.id}
            onChange={(selectedOptions) => {
              handleChange({
                target: {
                  name: 'user_id',
                  value: selectedOptions,
                },
              });
            }}
            styles={TIME_HISTORY_SELECTION_BOX}
            components={{
              SingleValue: (props) => <CustomSingleValue {...props} icon={<User />} title='User' />,
            }}
            placeholder={
              <div className='d-flex align-items-center gap-1'>
                <User /> User
              </div>
            }
            value={selectedUser}
          />
        </div>

        <ReportDatePicker
          onDateChange={handleDateChange}
          initialStartDate={dateRange?.startDate}
          initialEndDate={dateRange?.endDate}
        />
        <div className='d-flex justify-content-end'>
          <Button.Container
            type='Save'
            handleOk={() => {
              handleSubmit();
            }}
            isDisabled={historyLoading}
            className='blue-button-inline-task'
          >
            <Button.Title title={'Submit'} className={'btn_txt m-0'} />
            <Button.Loading color='#fff' size={20} />
          </Button.Container>
        </div>
      </div>
      <section className='mt-2'>
        <div className='table_outer'>
          <table className='table table-hover tbl mb-0'>
            <thead>
              <tr>
                <th style={{ width: 'fit-content' }}>
                  <div className='head-container'>
                    <Hash size={15} />
                    <span>Sl.No</span>
                  </div>
                </th>
                <th>
                  <div className='head-container'>
                    <Calendar size={15} />
                    <span>Date</span>
                  </div>
                </th>
                <th>
                  <div className='head-container'>
                    <Shapes size={15} />
                    <span> Worked time</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: 'middle' }}>
              {historyLoading && (
                <TableRow noOfCols={8}>
                  <LoadingIcon color='blue' size={60} />
                </TableRow>
              )}

              {isPaused && <TableRow noOfCols={8}>No Internet</TableRow>}

              {isSuccess && !isPaused && tableData}
            </tbody>
          </table>
          {isSuccess && !isPaused && (
            <>
              <div style={{ textAlign: 'center', marginLeft: '19vw' ,marginBottom:'10px'}}>
                Total Worked Hours in Time : {hoursInTime.toString().padStart(2, '0')}:
                {minutesInTime.toString().padStart(2, '0')}
              </div>
              <div style={{ textAlign: 'center', marginLeft: '19vw' }}>
              Total Worked Hours <span className='fz-10px'>(base 100)</span> : {hours?.toString()?.padStart(2, '0')}:
                {minutes?.toString()?.padStart(2, '0')}
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export const CustomSingleValue = ({ children, icon, title = '', ...props }) => {
  return (
    <components.SingleValue {...props}>
      <div className='d-flex align-items-center gap-1'>
        {icon} {title} {title ? ':' : ''} {children}
      </div>
    </components.SingleValue>
  );
};
