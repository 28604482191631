import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../_components/CustomButton';
import { alertConstants, MENU } from '../_constants';
import { clientService } from '../_services';
import { ClientState, GlobalContext } from '../Utils';

export function ClientAdd({ id, handleClose = () => {}, afterClientAdd = () => {} }) {
  const { getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);
  const [client, setClient] = useState(ClientState);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState({ common: '' });
  const [query, setQuery] = useState({
    select: ['id', 'name', 'phone', 'email', 'company_name', 'description', 'pin_code', 'address'],
  });

  useEffect(() => {
    if (id && id != '0') {
      query.id = id;
      getAllClients(query);
    }
  }, [id]);

  // get client details for edit if id >0
  const getAllClients = async (queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    setQuery(queryNew);
    setIsLoading(true);
    try {
      const org = await clientService.clientGet(queryNew);
      if (org?.data?.length) {
        setClient(org.data[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    console.log(error);
  };
  // handler for client add or edit
  const handleChange = (event) => {
    const { name, value } = event.target;
    setClient({
      ...client,
      [name]: value,
    });
  };

  // handler for client form submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSubmitted(true);
    if (client?.name.toString().trim() === '') {
      setIsLoading(false);
      return null;
    }
    const payload = {
      ...client,
    };
    console.log(payload);
    try {
      await clientService.clientAdd(payload).then((data) => {
        setSubmitted(false);
        afterClientAdd(data?.data);
      });
      handleClose();
    } catch (error) {
      setError({
        common: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };

  return (
    <div className='px-3  py-3' style={{ fontSize: '13px' }}>
      <form className='d-flex flex-column gap-2' onSubmit={handleSubmit}>
        <div className='row'>
          <div className='form-group'>
            <label className='mb-1 fz-13px-rem '>
              Name <span className='field-required'>*</span>
            </label>
            <input
              type='text'
              className='input-box'
              id='name'
              autoFocus
              name='name'
              value={client?.name}
              placeholder={`Enter ${LABEL_OF_CLIENT} Name `}
              onChange={(event) => {
                handleChange(event);
              }}
            />
            {submitted && !client?.name.trim() && (
              <div className='help-block'>{LABEL_OF_CLIENT} Name is required</div>
            )}
          </div>
        </div>
        <div className='row'>
          <div>
            <label className='mb-1 fz-13px-rem '>{LABEL_OF_CLIENT} Address</label>
            <textarea
              className='form-control box-focus fz-16px'
              name='address'
              value={client?.address}
              placeholder='Enter Address '
              onChange={(event) => {
                handleChange(event);
              }}
              style={{ borderColor: '#87909e' }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='form-group'>
            <label className='mb-1 fz-13px-rem '>PIN code</label>
            <input
              type='number'
              className='input-box'
              name='pin_code'
              value={client?.pin_code}
              placeholder='Enter PIN code'
              onChange={(event) => {
                handleChange(event);
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='form-group'>
            <label className='mb-1 fz-13px-rem '>Company Name</label>
            <input
              type='text'
              className='input-box'
              id='company_name'
              name='company_name'
              value={client?.company_name}
              placeholder='Enter Company Name '
              onChange={(event) => {
                handleChange(event);
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='form-group'>
            <label className='mb-1 fz-13px-rem '>Company Description</label>
            <textarea
              className='form-control box-focus fz-16px'
              id='description'
              name='description'
              value={client?.description}
              placeholder='Enter Description '
              onChange={(event) => {
                handleChange(event);
              }}
              style={{ borderColor: '#87909e' }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='form-group'>
            <label className='mb-1 fz-13px-rem '>Email</label>
            <input
              type='text'
              className='input-box'
              id='email'
              name='email'
              value={client?.email}
              placeholder='Enter Email'
              onChange={(event) => {
                handleChange(event);
              }}
            />
          </div>
        </div>

        <div className='row'>
          <div className='form-group'>
            <label className='mb-1 fz-13px-rem '>Phone</label>
            <input
              type='text'
              className='input-box'
              id='phone'
              name='phone'
              value={client?.phone}
              placeholder='Enter Phone'
              onChange={(event) => {
                handleChange(event);
              }}
            />
          </div>
        </div>
        <div className='row '>
          <div className=' d-flex justify-content-end gap-2'>
            {!id && (
              <Button.Container
                className={'cancel_btn'}
                type='reset'
                handleOk={() => {
                  setClient(ClientState);
                  setSubmitted(false);
                }}
              >
                <Button.Title title={'Cancel'} />
              </Button.Container>
            )}
            <Button.Container type='submit' isDisabled={isLoading}>
              <Button.Title title={'Save'} />
              <Button.Loading isLoading={isLoading} />
            </Button.Container>
          </div>
        </div>
      </form>
    </div>
  );
}
